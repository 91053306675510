import { Controller } from '@hotwired/stimulus';
import { getZendeskClient } from '../packs/helpers/zendesk_helper';

export default class extends Controller {
  parentIDField = `ticket.customField:custom_field_${import.meta.env.VITE_PARENT_ID_FIELD_ID}`;
  formLabelField = `ticket.customField:custom_field_${import.meta.env.VITE_FORM_ELEMENT_LABEL_FIELD_ID}`;

  async connect() {
    if (window.ticketId) {
      const externalId = await window.client.get('ticket.externalId');
      this.addExternalIdToDOM(externalId['ticket.externalId']);
      this.addTurboFrameLoadListener(externalId['ticket.externalId']);
      return;
    }

    if (!window.client) {
      await this.initializeZendeskClient();
    }

    const formLabel = await window.client.get(this.formLabelField);

    if (this.isNewTicket()) {
      this.setTicketSubmitHandler();
    } else if (window.ticketId) {
      this.handleExistingTicket(formLabel);
    } else if (window.context.location === 'new_ticket_sidebar' || formLabel[this.formLabelField] == undefined) {
      await this.handleNewTicketSidebar();
    }
  }

  async initializeZendeskClient() {
    try {
      window.client = await getZendeskClient();
      const data = await window.client.get('ticket.id');
      window.ticketId = data['ticket.id'];
      window.context = await window.client.context();

      window.addEventListener('categorised', (e) => {
        window.client.set(this.formLabelField, [JSON.parse(e.detail).category]);
      });
    } catch (error) {
      console.warn(`Could not initialize Zendesk client: ${error}`);
    }
  }

  async handleExistingTicket(formLabel) {
    const externalId = await this.getTicketExternalId();
    this.addExternalIdToDOM(externalId);
    this.addTurboFrameLoadListener(externalId);
    const redirectLocation = `/external/zendesk/redirect/${window.ticketId}?external_id=${externalId}`;
    if (formLabel[this.formLabelField] == undefined) {
      fetch(`/external/zendesk_tickets/fetch_category?remote_id=${window.ticketId}&existing_ticket=true`)
        .then((response) => response.json())
        .then((data) => {
          window.client.set(this.formLabelField, data.category);
        });
    }
    Turbo.visit(redirectLocation);
  }

  async getTicketExternalId() {
    const externalIdData = await window.client.get('ticket.externalId');
    return externalIdData['ticket.externalId'];
  }

  addExternalIdToDOM(externalId) {
    const forms = document.querySelectorAll('form');
    forms.forEach((form) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'external_id';
      input.value = externalId;
      form.appendChild(input);
    });

    const links = document.querySelectorAll('a');
    links.forEach((link) => {
      const href = link.getAttribute('href');
      const newHref = href?.match(/\?./) ? href + `&external_id=${externalId}` : href + `?external_id=${externalId}`;
      link.setAttribute('href', newHref);
    });
  }

  addTurboFrameLoadListener(externalId) {
    document.addEventListener('turbo:frame-load', () => {
      this.addExternalIdToDOM(externalId);
    });
  }

  isNewTicket() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('new_ticket') === 'true';
  }

  async handleNewTicketSidebar() {
    const parentIDResponse = await window.client.get(this.parentIDField);
    const parentID = parentIDResponse[this.parentIDField];

    if (parentID) {
      fetch(`/external/zendesk_tickets/fetch_category?remote_id=${parentID}`)
        .then((response) => response.json())
        .then((data) => {
          window.client.set(this.formLabelField, data.category);
          if (data.support_request_id) {
            const location = `/external/zendesk_tickets/display_category?support_request_id=${data.support_request_id}&new_ticket=true`;
            Turbo.visit(location);
          } else {
            const location = `/external/zendesk/redirect?new_ticket=true`;
            Turbo.visit(location);
          }
        });
    } else {
      const location = `/external/zendesk/redirect?new_ticket=true`;
      Turbo.visit(location);
    }
  }

  setTicketSubmitHandler() {
    window.client.on('ticket.submit.start', async () => {
      try {
        const support_request_id = new URLSearchParams(window.location.search).get('support_request_id');
        window.client.set('ticket.tags', [`support_request_${support_request_id}`]);
      } catch (error) {
        console.error('Error occurred while getting ticket id: ', error);
      }
      return true;
    });
  }

  import() {
    delete window.ticketId;
  }
}
