import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="disable-form-button-after-submit-temporarily"
export default class extends Controller {
  static values = { duration: Number };
  static targets = ['button'];
  disable() {
    this.buttonTarget.disabled = true;
    setTimeout(() => {
      this.buttonTarget.disabled = false;
    }, this.durationValue);
  }
}
