import { Controller } from "@hotwired/stimulus";
import lottie from "lottie-web";

// Fetch a lottie jsonFile and displays it on the container
// The file needs to be in app/assets/jsons/lottie
export default class extends Controller {
  static targets = [ "container" ];
  static values = { jsonFile: String };

  // This method probably shouldn't be a `connect` but rather a manually triggered action
  connect() {
    import(`../../assets/jsons/lottie/${this.jsonFileValue}.json`).then(file => {
      const jsonFileCopy = { ...file }; // the returned file is protected, so we need to do a copy of the object
      lottie.loadAnimation({
        container: this.containerTarget,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: jsonFileCopy,
      });
    });
  }
}
