import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    windowFunction: String,
  };

  connect() {
    window[this.windowFunctionValue]();
  }
}
