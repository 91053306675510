import { Controller } from '@hotwired/stimulus';
import { Collapse } from 'bootstrap';

// Automatically close all opened collapsible elements when the mouse leaves the controller's host.
// Originally made to be used with the global navigation sidebar.
export default class extends Controller {
  connect() {
    this.element.addEventListener('mouseleave', () => {
      // /!\ For whatever reason, instanciating a Collapse also opens it.
      // To avoid that, Collapse are only instanciated for already opened/opening collapse elements.
      const shownCollapses = [...this.element.querySelectorAll('.collapse.show, .collapsing')].map(
        (el) => new Collapse(el),
      );

      // /!\ collapse.hide() doesn't work if the collapse is not in a fully opened state.
      // As a workaround, the method call is delayed to let the collapse fully open.
      // The timeout duration maps to the default Bootstrap animations' .15s duration.
      setTimeout(() => {
        shownCollapses.forEach((collapse) => collapse.hide());
      }, 150);
    });
  }
}
