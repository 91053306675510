import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { url: String }

  connect() {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }

  handleUpdateSelect(filledSelects, nextSelectName, callback) {
    let lastSelectValue = $(`#flat_contract_loss_${filledSelects[filledSelects.length - 1]}`).val()

    if (lastSelectValue === '') {
      this.hideFollowingInputs(filledSelects[filledSelects.length - 1])
      $('#flat-contract-loss-modal-opener').prop("disabled", "disabled")
      return
    }

    let filters = this.generateFilters(filledSelects)

    this.updateOptions(filters, nextSelectName, callback)
    this.hideFollowingInputs(nextSelectName)
    $(`#${nextSelectName}-container`).show()
    $('#flat-contract-loss-modal-opener').prop("disabled", nextSelectName === 'contract_recovery' ? "" : "disabled")
  }

  statusUpdated() {
    this.handleUpdateSelect(['team', 'status'], 'reason', this.updateSelect)
    $('#modal-status').html($('#flat_contract_loss_status').val() + ' -')
  }

  teamUpdated() {
    this.handleUpdateSelect(['team'], 'status', this.updateSelect)
  }

  reasonUpdated() {
    this.handleUpdateSelect(['team', 'status', 'reason'], 'contract_recovery', this.updateCheckBoxes)
    $('#modal-reason').html($('#flat_contract_loss_reason').val())
  }

  updateOptions(filters, key, callbackFunction) {
    fetch(`${this.urlValue}?${filters}info_key=${key}`)
      .then(function (response) {
        return (response.json())
      }).then(buffer => callbackFunction(buffer, key))
  }

  updateCheckBoxes(options) {
    let container = $('#flat_contract_loss_recovery_contract_methods')

    container.html('')
    options.forEach( function (option, index) {
      container.append('<div class="form-check border-top mb-0">' +
        `<input class="form-check-input ml-0" id="recovery-method-${index + 1}" type="checkbox" value="${option}" name="flat_contract_loss[used_contract_recovery_methods][]"/>` +
        `<label class="form-check-label d-block ml-4" for="recovery-method-${index + 1}">${option}` +
        '</label></div>')
    })
  }

  updateSelect(options, selectId) {
    let select = $('#flat_contract_loss_' + selectId)

    select.html('')
    select.append($('<option>', { value: null, text: null}))
    options.forEach(option => select.append($('<option>', { value: option, text: option})))
  }

  hideFollowingInputs(lastShownInput) {
    let inputs = [ 'contract_recovery', 'reason', 'status', 'team']

    for (const input of inputs) {
      if (input === lastShownInput)
        return
      $(`#${input}-container`).hide()
    }
  }

  generateFilters(criterion) {
    let filterValue
    return criterion.map( function (criteria) {
      filterValue = $(`#flat_contract_loss_${criteria}`).val()
        return `filters%5B${encodeURIComponent(criteria)}%5D=${encodeURIComponent(filterValue)}&`;
    }).join('')
  }
}
