import { Controller } from '@hotwired/stimulus';
import { debounce } from 'lodash-es';

export default class extends Controller {
  static targets = ['row', 'share', 'amount'];

  static values = {
    total: { type: Number, default: 0 },
    debounceTime: { type: Number, default: 300 },
  };

  initialize() {
    this.compute_amount = debounce(this.compute_amount.bind(this), this.debounceTimeValue);
  }

  compute_amount(event) {
    const amount = this.amountTargets[this.shareTargets.indexOf(event.target)];
    amount.textContent = this.#formatCurrency((this.totalValue * event.target.value) / 100);
  }

  #formatCurrency(amount) {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
    }).format(amount);
  }
}
