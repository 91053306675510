import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="landlord--flat-list"
export default class extends Controller {
  static targets = ['table'];
  showFlatPage(event) {
    Turbo.cache.clear();
    Turbo.visit(event.currentTarget.dataset.href);
  }
}
