import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "keysTransitionSelect" ];

  render(event) {
    const path  = event.params.path;
    const keys_transition_id = event.target.selectedOptions[0].value;

    const regex = /keys_transitions\/\d+\//;
    document.getElementById('internal_canvas').src = path.replace(regex, 'keys_transitions/' + keys_transition_id +'/');
  }
}
