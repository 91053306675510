import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'completionAlert', 'submitButton'];

  connect() {
    this.updateSubmitButton();
  }

  updateSubmitButton() {
    const allChecked = this.checkboxTargets.every((checkbox) => checkbox.checked);
    this.submitButtonTarget.disabled = !allChecked;
    this.completionAlertTarget.style.display = allChecked ? 'none' : 'block';
  }

  toggleSubmit() {
    this.updateSubmitButton();
  }
}
