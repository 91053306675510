import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="relative-dates"
export default class extends Controller {
  static targets = ['minDateInput', 'maxDateInput'];

  connect() {
    this.listener = this.minDateInputTarget.addEventListener('change', () => {
      this.maxDateInputTarget.setAttribute('min', this.minDateInputTarget.value);
    });
  }

  disconnect() {
    this.minDateInputTarget.removeEventListener('change', this.listener);
  }
}
