import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="check-before-submit"
export default class extends Controller {
  static targets = ['checkbox', 'error'];

  updateError() {
    if (this.checkboxTargets.every((x) => x.checked)) {
      this.errorTarget.style.display = 'none';
    }
  }

  verifyCheckboxes(e) {
    e.preventDefault();
    if (this.checkboxTargets.every((x) => x.checked)) {
      e.target.submit();
    } else {
      this.errorTarget.style.display = 'block';
    }
  }
}
