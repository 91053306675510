import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'supportRequestListContainer',
    'supportRequestListDiv',
    'supportRequestList',
    'showSupportRequest',
    'supportRequest',
    'senderForm',
  ];

  senderFormTargetConnected(element) {
    if (window.innerWidth >= 992) {
      element.classList.add('d-none');
    }
  }

  toggle(event) {
    this.supportRequestTargets.forEach((target) => {
      target.classList.remove('active');
    });

    if (!event.detail) {
      //event.detail is not defined when the event is turbo:frame-load
      let params = new URL(document.location).searchParams;
      let requestId = params.get('support_request_group_id');
      let request = document.getElementById(`support-request-${requestId}`);
      request && request.classList.add('active');
    } else {
      event.currentTarget.classList.add('active');
    }
    const isLargeScreen = window.innerWidth >= 992; // 992px est la résolution "lg" de Bootstrap
    if (!isLargeScreen) {
      this.supportRequestListContainerTarget.classList.toggle('d-none');
      this.showSupportRequestTarget.classList.toggle('d-none');
      let bottomNavbar =
        document.getElementById('navbar-bottom') || document.getElementsByClassName('navbar-fixed-bottom')[0];
      bottomNavbar.classList.toggle('d-none');

      if (event.params.destination === 'list') {
        let showFrame = document.getElementById('support-request-show');
        while (showFrame.hasChildNodes()) {
          showFrame.removeChild(showFrame.firstChild);
        }
      }
    }
  }

  resize() {
    let element = document.querySelector('.thread');
    if (window.innerWidth >= 767 && element) {
      const navbarTopHeight = (
        document.getElementById('navbar-top') || document.getElementsByClassName('navbar-fixed-top')[0]
      ).offsetHeight;
      const descriptionElement = document.getElementById('description-show');
      const alertElement = document.getElementById('alert-show');
      const alertRequester = document.getElementById('alert-requester-type');
      const descriptionHeight = descriptionElement ? descriptionElement.offsetHeight : 0;
      const alertHeight = alertElement ? alertElement.offsetHeight : 0;
      const alertRequesterHeight = alertRequester ? alertRequester.offsetHeight : 0;
      const height = descriptionHeight + alertHeight + alertRequesterHeight;
      element.style.height = `calc(100vh - ${height + navbarTopHeight + 1}px)`;
    }
    if (window.innerWidth >= 992 && this.hasSenderFormTarget) {
      this.senderFormTarget.classList.remove('d-none');
      this.supportRequestListContainerTarget.classList.remove('d-none');
      this.scrollToBottom();
    }
  }

  scrollToBottom() {
    const div = document.getElementById('messages');
    if (!div) return;

    div.scrollTo({
      top: div.scrollHeight,
      behavior: 'smooth',
    });
  }

  scrollToTop() {
    if (!this.supportRequestListDivTarget) return;

    this.supportRequestListDivTarget.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}
