import { Controller } from '@hotwired/stimulus';

// Handle send support request
export default class extends Controller {
  static targets = ['form', 'formSubmit'];

  sendForm(event) {
    event.preventDefault();
    const valid = [];
    this.formTargets.forEach((form) => {
      valid.push(form.reportValidity());
    });
    if (valid.every((x) => x)) {
      event.target.disabled = true;
      document.getElementById('spinner').classList.remove('d-none');
      setTimeout(() => {
        this.formSubmitTarget.requestSubmit();
      }, 500);
    }
  }
}
