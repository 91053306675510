import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="email-validation"
// Adds formnovalidate to button so that an email can be submitted on its own,
// event if the next fields of the step are empty
export default class extends Controller {
  static targets = ['input', 'button'];

  updateButtonFormValidate() {
    if (this.inputTarget.checkValidity()) {
      this.buttonTarget.setAttribute('formnovalidate', 'formnovalidate');
    } else {
      this.buttonTarget.removeAttribute('formnovalidate');
    }
  }
}
