import { Controller } from '@hotwired/stimulus';
import hotkeys from 'hotkeys-js';

// Connects to data-controller="upload_navigation"
export default class extends Controller {
  connect() {
    hotkeys('left', { keyup: true, keydown: false }, function () {
      document.getElementById('previousButton').click();
    });

    hotkeys('right', { keyup: true, keydown: false }, function () {
      document.getElementById('nextButton').click();
    });

    //to disable horizontal scroll on key down
    hotkeys('left,right', { keyup: false, keydown: true }, () => {
      return false;
    });
  }

  refreshFrame(event) {
    if (!event.detail.success) {
      return;
    }

    let upload_tab_form = document.getElementById('upload_tab_form');
    if (upload_tab_form != null) {
      upload_tab_form.requestSubmit();
    }
  }

  disconnect() {
    hotkeys.unbind('left');
    hotkeys.unbind('right');
  }
}
