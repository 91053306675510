import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="select-payment-request"
export default class extends Controller {
  static targets = ['chargedAmount', 'refundAmount'];
  static outlets = ['recompute-last-payment'];

  toggle(event) {
    this.element.classList.toggle('bg-gray-200');
    if (event.currentTarget.checked) {
      this.element.setAttribute('data-recompute-last-payment-target', 'checked');
      this.chargedAmountTarget.setAttribute('data-recompute-last-payment-target', 'chargedAmount');
      this.refundAmountTarget.setAttribute('data-recompute-last-payment-target', 'refundAmount');
    } else {
      this.element.setAttribute('data-recompute-last-payment-target', 'unchecked');
      this.chargedAmountTarget.removeAttribute('data-recompute-last-payment-target');
      this.refundAmountTarget.removeAttribute('data-recompute-last-payment-target');
    }
    if (this.hasRecomputeLastPaymentOutlet) {
      this.recomputeLastPaymentOutlet.computeTotalAmount();
    }
  }
}
