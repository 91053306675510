import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="internal--select-irl"
export default class extends Controller {
  static values = { irlValues: String };
  static targets = ['irlInput', 'irlTrimester'];

  connect() {
    this.updateIrlValue();
  }

  updateIrlValue() {
    let hash = JSON.parse(this.irlValuesValue).reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
    this.irlInputTarget.value = hash[this.irlTrimesterTarget.value];
  }
}
