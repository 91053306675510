import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['remoteId'];

  updateRemoteId(event) {
    const url = event.target.value;
    this.remoteIdTarget.value = url.split('/').pop();
  }
}
