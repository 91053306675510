import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="class-toggle"
export default class extends Controller {
  static targets = ['element'];
  static values = {
    initialClass: String,
    newClass: String,
    onlyOnce: Boolean,
  };

  initialize() {
    this.toggled = false;
  }

  toggle(event) {
    if (this.toggled && this.hasOnlyOnceValue && this.onlyOnceValue) {
      return;
    }

    event.preventDefault();
    if (this.elementTarget.classList.contains(this.initialClassValue)) {
      this.setNewClass();
    } else {
      this.setInitialClass();
    }
    this.toggled = true;
  }

  // private

  setNewClass() {
    this.elementTarget.classList.remove(this.initialClassValue);
    this.elementTarget.classList.add(this.newClassValue);
  }

  setInitialClass() {
    this.elementTarget.classList.remove(this.newClassValue);
    this.elementTarget.classList.add(this.initialClassValue);
  }
}
