import { Controller } from "@hotwired/stimulus"

// Connects to a modal and close on submit
export default class extends Controller {
    static targets = ["modal"];

    submit() {
        let modal = bootstrap.Modal.getInstance(this.modalTarget);
        modal.hide();
    }
}
