import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="form-elements"
export default class extends Controller {
  static targets = ['form'];
  connect() {
    this.timeout = null;
  }

  saveFormElementInput(event) {
    const milliseconds = event.target.parentElement.dataset.timeout ?? 0;
    const form = this.formTarget;
    const wrappers = document.getElementsByClassName('request-form-wrapper');
    const current_index = this.getCurrentIndex(event, wrappers) + 1;
    const count = wrappers.length;

    this.appendHiddenInput(form, 'support_request[current_index]', current_index);
    this.appendHiddenInput(form, 'support_request[count]', count);

    this.submitFormWithDelay(form, milliseconds);
  }

  getCurrentIndex(event, wrappers) {
    return Array.prototype.indexOf.call(wrappers, event.target.closest('.request-form-wrapper'));
  }

  appendHiddenInput(form, name, value) {
    const input = document.createElement('input');
    input.type = 'text';
    input.hidden = true;
    input.name = name;
    input.value = value;
    form.appendChild(input);
  }

  submitFormWithDelay(form, milliseconds) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (typeof form.requestSubmit === 'function') {
        form.requestSubmit();
      } else {
        this.submitFormFallback(form);
      }
    }, milliseconds);
  }

  submitFormFallback(form) {
    const submitter = document.createElement('input');
    submitter.type = 'submit';
    submitter.hidden = true;
    form.appendChild(submitter);
    submitter.click();
    form.removeChild(submitter);
  }
}
