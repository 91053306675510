import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkup"
export default class extends Controller {
  static targets = [ "checkbox", "submitButton" ]

  checkIfCheckupWasValid() {
    let isValidated = true;
    this.checkboxTargets.forEach(function(checkbox) {
      isValidated &&= checkbox.checked;
    });
    this.submitButtonTarget.disabled = !isValidated;
  }
}
