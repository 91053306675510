import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['listServices', 'showServices', 'serviceItem'];

  toggle(event) {
    this.serviceItemTargets.forEach(function (element) {
      element.classList.remove('bg-gray-100');
    });
    event.currentTarget.classList.add('bg-gray-100');
    const isLargeScreen = window.innerWidth >= 992; // 992px est la résolution "lg" de Bootstrap
    if (!isLargeScreen) {
      this.listServicesTarget.classList.toggle('d-none');
      this.showServicesTarget.classList.toggle('d-none');
    }
  }
}
