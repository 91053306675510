import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "smokeDetectorDamageType", "isSmokeDetectorDamaged",
    "smokeDetectorDamageTypes",
    "batteriesWarning",
    "noBatteries",
    "smokeDetectorOtherProblem",
    "smokeDetectorDamageDetails",
    "smokeDetectorDamageDetailsContainer",
    "hadWaterDamage",
    "waterDamageDegradations",
    "waterDamageDegradationsRepaired",
    "waterDamageDegradationsContainer",
    "waterDamageDegradationsRepairedContainer",
    "waterDamageDetailsContainer",
    "waterDamageDamageDetails",
    "hadParasites",
    "parasitesProblemFixedContainer",
    "parasitesProblemFixed",
    "parasitesDetailsContainer",
    "parasitesDetails",
    "needsRepairs",
    "repairsDetailsContainer",
    "repairsDetails",
    "needsImprovements",
    "improvementsDetailsContainer",
    "improvementDetails",
    "picturesNeedRenewal",
    "picturesRenewalDetailsContainer",
    "picturesRenewalDetails"
  ]

  connect(){
    this.askDamageType();
    this.askForWaterDamageDegradations();
    this.askWaterDamageReparationsStatus();
    this.hideWaterDamageDetails();
    this.askParasitesProblemStatus();
    this.askRepairsDetails();
    this.askImprovementsDetails();
    this.askPicturesRenewalDetails();
  }

  askDamageType(){
    this.smokeDetectorDamageTypesTarget.style.display = this.isSmokeDetectorDamagedTarget.checked ? 'block' : 'none'
    this.smokeDetectorDamageTypeTargets.forEach((element) => {
      element.required = this.isSmokeDetectorDamagedTarget.checked
    })
    if(!this.isSmokeDetectorDamagedTarget.checked){
      this.smokeDetectorDamageTypeTargets.forEach((element) => { element.checked = false })
    }
    this.handleDamageType();
  }

  handleDamageType(){
    this.batteriesWarningTarget.style.display = this.noBatteriesTarget.checked ? 'block' : 'none';
    this.smokeDetectorDamageDetailsContainerTarget.style.display = this.smokeDetectorOtherProblemTarget.checked ? 'block' : 'none';
    this.smokeDetectorDamageDetailsTarget.required = this.smokeDetectorOtherProblemTarget.checked
    if (!this.smokeDetectorOtherProblemTarget.checked) this.smokeDetectorDamageDetailsTarget.value = '';
  }

  askForWaterDamageDegradations(){
    let checked = this.hadWaterDamageTarget.checked
    this.waterDamageDegradationsContainerTarget.style.display = checked ? 'block' : 'none'
    if (!checked){
      this.waterDamageDegradationsRepairedContainerTarget.style.display = 'none';
      this.waterDamageDetailsContainerTarget.style.display = 'none';
      this.waterDamageDegradationsTarget.checked = false;
      this.waterDamageDegradationsRepairedTarget.checked = false;
      this.waterDamageDamageDetailsTarget.value = '';
    }
  }

  askWaterDamageReparationsStatus(){
    let checked = this.waterDamageDegradationsTarget.checked
    this.waterDamageDegradationsRepairedContainerTarget.style.display = checked ? 'block' : 'none';
    this.waterDamageDetailsContainerTarget.style.display =  checked ? 'block' : 'none';
    if (!checked){
      this.waterDamageDegradationsRepairedContainerTarget.style.display = 'none';
      this.waterDamageDegradationsRepairedTarget.checked = false;
      this.waterDamageDamageDetailsTarget.value = '';
    }
  }

  hideWaterDamageDetails(){
    this.waterDamageDetailsContainerTarget.style.display = this.waterDamageDegradationsRepairedTarget.checked ? 'none' : 'block';
    if (this.waterDamageDegradationsRepairedTarget.checked) this.waterDamageDamageDetailsTarget.value = '';
  }

  askParasitesProblemStatus(){
    this.parasitesProblemFixedContainerTarget.style.display = this.hadParasitesTarget.checked ? 'block' : 'none';
    this.parasitesDetailsContainerTarget.style.display = this.parasitesProblemFixedTarget.checked ? 'none' : 'block';
    if (!this.hadParasitesTarget.checked){
      this.parasitesProblemFixedTarget.checked = false;
      this.parasitesDetailsTarget.value = '';
    }
  }

  askParasitesDetails(){
    this.parasitesDetailsContainerTarget.style.display = this.parasitesProblemFixedTarget.checked ? 'none' : 'block';
    if (!this.parasitesProblemFixedTarget.checked) this.parasitesDetailsTarget.value = '';
  }

  askRepairsDetails(){
    this.repairsDetailsContainerTarget.style.display = this.needsRepairsTarget.checked ? 'block' : 'none';
    this.repairsDetailsTarget.required = this.needsRepairsTarget.checked;
    if (!this.needsRepairsTarget.checked) this.repairsDetailsTarget.value = '';
  }

  askImprovementsDetails(){
    this.improvementsDetailsContainerTarget.style.display = this.needsImprovementsTarget.checked ? 'block' : 'none';
    this.improvementDetailsTarget.required = this.needsImprovementsTarget.checked;
    if (!this.needsImprovementsTarget.checked) this.improvementDetailsTarget.value = '';
  }

  askPicturesRenewalDetails(){
    this.picturesRenewalDetailsContainerTarget.style.display = this.picturesNeedRenewalTarget.checked ? 'block' : 'none';
    if (!this.picturesNeedRenewalTarget.checked) this.picturesRenewalDetailsTarget.value = '';
  }
}
