import { Controller } from '@hotwired/stimulus';
import { DirectUpload } from '@rails/activestorage';

export default class extends Controller {
  static targets = ['input', 'progressWrapper', 'progressBar', 'attachmentHiddenField'];

  connect() {
    this.setProgressBarHidden(true);
    this.originalInputTargetId = this.inputTarget.getAttribute('id');
    this.originalInputTargetName = this.inputTarget.getAttribute('name');
    this.inputTarget.removeAttribute('name');
    if (this.hasAttachmentHiddenFieldTarget) {
      const blob = {
        signed_id: this.attachmentHiddenFieldTarget.value,
        byte_size: parseInt(this.attachmentHiddenFieldTarget.dataset.byteSize),
        filename: this.attachmentHiddenFieldTarget.dataset.filename,
      };
      this.attachmentHiddenFieldTarget.remove();
      this.handleBlobCreated(blob);
    }
  }

  uploadFile(file) {
    this.setProgressBarProgress(0);

    new DirectUpload(
      file,
      this.inputTarget.dataset.directUploadUrl,
      this, // callback directUploadWillStoreFileWithXHR(request)
    ).create((error, blob) => {
      if (error) {
        console.log(error);
      } else {
        this.handleBlobCreated(blob);
      }
    });
  }

  inputChanged() {
    Array.from(this.inputTarget.files).forEach((file) => this.uploadFile(file));

    this.inputTargets.forEach((input) => {
      input.value = null;
    });
  }

  handleBlobCreated(blob) {
    console.log('handleBlobCreated blob', blob);
    this.setProgressBarHidden(true);
    this.deleteAttachment();

    // Create the hidden field representing the blob
    this.hiddenField = document.createElement('input');
    this.hiddenField.setAttribute('id', this.originalInputTargetId);
    this.hiddenField.setAttribute('name', this.originalInputTargetName);
    this.hiddenField.setAttribute('type', 'hidden');
    this.hiddenField.setAttribute('value', blob.signed_id);
    this.inputTarget.after(this.hiddenField);

    // And create the preview for the blob
    this.filePreviewElement = document.createElement('div');
    this.filePreviewElement.className = 'mt-3';
    this.filePreviewElement.innerHTML = `${blob.filename} (${this.humanFileSize(blob.byte_size)})`;
    const button = document.createElement('button');
    button.type = 'button';
    button.classList.add('btn-close');
    button.ariaLabel = 'Close';
    button.dataset['action'] = 'click->internal--document-form#deleteAttachment';
    this.filePreviewElement.appendChild(button);
    this.inputTarget.after(this.filePreviewElement);
  }

  deleteAttachment() {
    if (this.hiddenField) {
      this.hiddenField.remove();
    }
    if (this.filePreviewElement) {
      this.filePreviewElement.remove();
    }
  }

  // ActiveStorage DirectUpload callback
  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', (event) => {
      const progress = (event.loaded / event.total) * 100;
      this.setProgressBarProgress(progress);
    });
  }

  setProgressBarHidden(hiddenValue) {
    if (hiddenValue) {
      this.progressWrapperTarget.classList.add('d-none');
    } else {
      this.progressWrapperTarget.classList.remove('d-none');
    }
  }

  setProgressBarProgress(progressValue) {
    this.setProgressBarHidden(false);
    this.progressBarTarget.style.width = `${progressValue}%`;
    this.progressBarTarget.setAttribute('aria-valuenow', progressValue);
  }

  humanFileSize(bytes) {
    const thresh = 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = ['Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];
    let u = -1;
    const r = 10;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return `${bytes.toFixed(1)} ${units[u]}`;
  }
}
