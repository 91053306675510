import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="manager--agent--estimations"
export default class extends Controller {
  static targets = ["address", "area", "charges", "city", "floor", "furnished", "latitude", "longitude", "rentcc", "roomcount", "zipcode"];
  static values = {
    url: String
  }

  connect() {
    this.definedTargets = this.checkTargetsExist();
    var flat_id = document.getElementById('flat_id');
    if (flat_id.value) {
      var event = new Event('input', {
        bubbles: true,
        cancelable: true,
      });
      flat_id.dispatchEvent(event);
    }
  }

  flatIdChange(event) {
    event.target.classList.remove("is-invalid");
    clearTimeout(this.timeout);
    if (event.target.value.length == 0) {
      return;
    }
    this.timeout = setTimeout(() => {
      fetch(`${this.urlValue}${parseInt(event.target.value)}`)
        .then((response) => response.json())
        .then((data) => {
          for (let index = 0; index < this.definedTargets.length; index++) {
            this.fillInTargetInputs(this.definedTargets[index], data)
          }
        })
        .catch(function (error) {
          console.log(error);
          event.target.classList.add("is-invalid");
        });
      }, 500);
  }

  checkTargetsExist(){
    return this.constructor.targets.filter(target => this[`has${this.capitalize(target)}Target`])
  }

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  fillInTargetInputs(target, data) {
    if (this[`${target}Target`].type === 'checkbox') {
      this[`${target}Target`].checked = data[target];
    } else {
      this[`${target}Target`].value = data[target];
    }
  }
}
