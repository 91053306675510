import { Controller } from '@hotwired/stimulus';
import { getZendeskClient } from '../packs/helpers/zendesk_helper';

export default class extends Controller {
  static values = {
    category: String,
  };

  async connect() {
    try {
      if (!window.client) {
        window.client = await getZendeskClient();
      }

      let ticket_field = `ticket.customField:custom_field_${import.meta.env.VITE_FORM_ELEMENT_LABEL_FIELD_ID}`;
      window.client.get(ticket_field).then((data) => {
        if (data[ticket_field] == null) {
          window.client.set(ticket_field, this.categoryValue);
        }
      });
    } catch (error) {
      console.warn(`Could not initialize Zendesk client: ${error}`);
    }
  }
}
