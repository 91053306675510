import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="save_form"
export default class extends Controller {
  showTenantApplication(event) {
    if (
      !event.target.classList.contains('can-redirect-show') &&
      (event.target.classList.contains('dropdown-action') || event.target.nodeName != 'TD')
    ) {
      return;
    }

    Turbo.cache.clear();
    Turbo.visit(event.currentTarget.dataset.tenantApplicationPath);
  }
}
