import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="auth--sign-up"
export default class extends Controller {
  static targets = ['errorEmail'];

  dismissEmailError() {
    if (this.hasErrorEmailTarget && this.errorEmailTarget.classList.contains('d-block')) {
      this.errorEmailTarget.classList.remove('d-block');
      this.errorEmailTarget.classList.add('d-none');
    }
  }
}
