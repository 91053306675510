import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';

// Connects to data-controller="checkup"
export default class extends Controller {
  static targets = ['formElementsList', 'formElement'];

  connect() {
    this.sortable = new Sortable(this.formElementsListTarget, {
      onEnd: () => {
        this.updateOrder();
      },
      animation: 150,
      chosenClass: 'sortable-chosen',
    });
  }

  updateOrder() {
    this.formElementTargets.forEach((formElement, index) => {
      const input = formElement.querySelector('input:first-child');
      input.value = index;
    });
  }
}
