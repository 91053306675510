import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="turbo-stream-update"
export default class extends Controller {
  // `turbo-stream-update-src-value`: must be a controller route path that supports the turbo_stream request format
  static values = { src: String };

  connect() {
    fetch(this.srcValue, {
      headers: { Accept: 'text/vnd.turbo-stream.html' },
    })
      .then((response) => response.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }
}
