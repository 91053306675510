import { Controller } from '@hotwired/stimulus';
import intlTelInput from 'intl-tel-input';
import utilsPath from 'intl-tel-input/build/js/utils.js?url';

// Connects to data-controller="phone-number-validation"
export default class extends Controller {
  static targets = ['phoneField'];

  connect() {
    this.phoneFieldTargets.forEach(function (phoneField) {
      const iti = intlTelInput(phoneField, {
        initialCountry: 'fr',
        utilsScript: utilsPath,
      });
      phoneField.addEventListener('focusout', () => {
        if (iti.isValidNumber()) {
          phoneField.setCustomValidity('');
          phoneField.classList.remove('is-invalid');
        } else {
          phoneField.setCustomValidity("Votre numéro n'est pas valide.");
          phoneField.classList.add('is-invalid');
        }
      });
    });
  }
}
