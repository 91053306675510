import { Controller } from '@hotwired/stimulus';

// To change text with event
export default class extends Controller {
  static targets = ['toChange'];
  static values = {
    newText: String,
    initialText: String,
  };

  changeText(event) {
    if (event.currentTarget.type === 'checkbox' && !event.currentTarget.checked) {
      this.toChangeTarget.innerText = event.currentTarget.dataset.textIfIsNotCheck;
    } else {
      this.toChangeTarget.innerText = event.currentTarget.dataset.textByDefault;
    }
  }

  toggleEventText(event) {
    if (event.currentTarget.innerText === this.initialTextValue) {
      event.currentTarget.innerText = this.newTextValue;
    } else {
      event.currentTarget.innerText = this.initialTextValue;
    }
  }
}
