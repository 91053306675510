import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="turbo-form"
export default class extends Controller {
  submit(event) {
    let form = event.target.closest('form');
    if (typeof form.requestSubmit === 'function') {
      form.requestSubmit();
    } else {
      var submitter = document.createElement('input');
      submitter.type = 'submit';
      submitter.hidden = true;
      form.appendChild(submitter);
      submitter.click();
      form.removeChild(submitter);
    }
  }
}
