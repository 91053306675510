import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'firstName',
    'lastName',
    'dob',
    'streetAddress1',
    'type',
    'businessName',
    'businessTaxId',
    'ibanNumber',
    'createAccountToken',
    'updateAccountToken',
    'personToken',
    'ibanToken',
    'paymentMethodToken',
    'country',
    'city',
    'zip',
    'waitMessages',
    'errors',
    'errorsIban',
    'submitBtn',
    'refreshIcon',
    'email',
  ];

  connect() {
    var stripePublishableKey = document.getElementById('third-parties-credentials').dataset.stripePublishableKey;
    this.stripe = Stripe(stripePublishableKey);

    this.ibanNumberTarget.addEventListener('input', this.formatIbanInput.bind(this));
    this.element.addEventListener('submit', this.handleForm.bind(this));
  }

  formatIbanInput(event) {
    let target = event.target;
    let position = target.selectionEnd;
    let length = target.value.length;
    target.value = target.value.replace(/[^\da-zA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim().toUpperCase();
    target.selectionEnd = position += target.value.charAt(position - 1) === ' ' && target.value.charAt(length - 1) === ' ' && length !== target.value.length ? 1 : 0;
  }

  handleForm(event) {
    event.preventDefault();

    this.disableSubmit();
    this.showWaitMessages();
    this.hideErrors();

    if (!this.validateIbanCountry() || !this.validateAddress() || !this.validateDob()) {
      return;
    }

    let accountType = this.typeTarget.value.toLowerCase();
    let ibanCountry = this.ibanNumberTarget.value.substring(0, 2).toUpperCase();

    this.stripe.createToken('account', this.accountData(accountType))
      .then(result => {
        if (result.error) throw result.error.message;
        this.createAccountTokenTarget.value = result.token.id;
        if (/\d/.test(ibanCountry)) ibanCountry = 'FR';
        this.countryTarget.value = ibanCountry;
        return this.stripe.createToken('person', this.personData());
      })
      .then(personResult => {
        if (personResult.error) throw personResult.error.message;
        this.personTokenTarget.value = personResult.token.id;
        return this.generateUpdateAccountToken();
      })
      .then(result => {
        if (result.error) throw result.error.message;
        this.updateAccountTokenTarget.value = result.token.id;
        return this.stripe.createToken('bank_account', {
          country: ibanCountry,
          currency: 'eur',
          account_number: this.ibanNumberTarget.value.replace(/\s/g, ''),
          account_holder_name: `${this.firstNameTarget.value} ${this.lastNameTarget.value}`,
          account_holder_type: this.typeTarget.value.toLowerCase(),
        });
      })
      .then(ibanResult => {
        if (ibanResult.error) throw ibanResult.error.message;
        this.ibanTokenTarget.value = ibanResult.token.id;
        return this.stripe.createPaymentMethod(this.paymentMethodData());
      })
      .then(res => {
        if (res.error) throw this.translateStripeError(res.error.code);
        this.paymentMethodTokenTarget.value = res.paymentMethod.id;
        this.element.submit();
      })
      .catch(error => this.handleError(error));
  }

  disableSubmit() {
    this.submitBtnTarget.setAttribute('disabled', 'disabled');
    this.refreshIconTarget.style.display = 'block';
  }

  showWaitMessages() {
    this.waitMessagesTarget.style.display = 'block';
  }

  hideErrors() {
    this.errorsTarget.style.display = 'none';
    this.errorsIbanTarget.style.display = 'none';
  }

  validateIbanCountry() {
    if (this.countryAddress && this.ibanNumberTarget.value.substring(0, 2).toUpperCase() != this.countryAddress) {
      this.handleError("Votre IBAN et votre adresse doivent être dans le même pays. Si vous n'habitez pas en France, vous pouvez rentrer l'adresse du bien en location.");
      return false;
    }
    return true;
  }

  validateAddress() {
    if (!this.cityTarget.value || !this.zipTarget.value || !this.streetAddress1Target.value) {
      this.handleError('Vérifiez que vous avez bien sélectionné une adresse parmi celles proposées. Dans le cas d’un RIB français, notre prestataire de paiement exige une adresse française, renseignez alors une de vos adresses en France ou bien l’adresse de votre logement.');
      return false;
    }
    return true;
  }

  validateDob() {
    let [year, month, day] = this.dobTarget.value.split('-').map(Number);
    if (!(day && month && year && day >= 1 && day <= 31 && month >= 1 && month <= 12)) {
      this.handleError("Votre date de naissance n'est pas correcte.");
      return false;
    }
    return true;
  }

  accountData(type) {
    return type === 'individual' ? this.individualAccountData() : this.companyAccountData();
  }

  individualAccountData() {
    return {
      business_type: 'individual',
      tos_shown_and_accepted: true,
    };
  }

  companyAccountData() {
    return {
      business_type: 'company',
      company: {
        name: this.businessNameTarget.value,
        tax_id: this.vatIdentificationNumberFrom(this.businessTaxIdTarget.value),
        address: {
          line1: this.streetAddress1Target.value,
          city: this.cityTarget.value,
          postal_code: this.zipTarget.value,
        },
      },
      tos_shown_and_accepted: true,
    };
  }

  personData() {
    let [year, month, day] = this.dobTarget.value.split('-');
    return {
      first_name: this.firstNameTarget.value,
      last_name: this.lastNameTarget.value,
      address: {
        line1: this.streetAddress1Target.value,
        city: this.cityTarget.value,
        postal_code: this.zipTarget.value,
      },
      dob: {
        day: Number(day),
        month: Number(month),
        year: Number(year),
      },
      relationship: {
        director: true,
        executive: true,
        representative: true,
        owner: true,
      },
    };
  }

  paymentMethodData() {
    return {
      type: 'sepa_debit',
      sepa_debit: {
        iban: this.ibanNumberTarget.value.replace(/\s/g, ''),
      },
      billing_details: {
        name: `${this.firstNameTarget.value} ${this.lastNameTarget.value}`,
        email: this.emailTarget.dataset.email,
      },
    };
  }

  generateUpdateAccountToken() {
    return this.stripe.createToken('account', {
      company: {
        directors_provided: true,
        owners_provided: true,
      },
      tos_shown_and_accepted: true,
    });
  }

  handleError(message) {
    this.waitMessagesTarget.style.display = 'none';
    this.errorsIbanTarget.textContent = message;
    this.errorsIbanTarget.style.display = 'block';
    this.submitBtnTarget.removeAttribute('disabled');
    this.refreshIconTarget.style.display = 'none';
  }

  translateStripeError(code) {
    switch (code) {
      case 'invalid_owner_name':
        return "Le nom renseigné n'est pas valide.";
      case 'invalid_bank_account_iban':
        return "L'IBAN renseigné n'est pas valide.";
      case 'processing_error':
        return 'Mode de paiement non disponible, veuillez choisir un autre mode de paiement';
      default:
        return 'Il y a eu une erreur à la création du mandat. Veuillez re-essayer.';
    }
  }
}
