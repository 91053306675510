import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['inputArea', 'messages'];

  connect() {
    this.inputAreaBaseHeight = this.inputAreaTarget.offsetHeight;
  }

  focus() {
    this.messagesTarget.scrollTo({
      top: this.messagesTarget.scrollHeight,
      behavior: 'smooth',
    });
  }

  resize() {
    this.inputAreaTarget.style.height = 0;
    const newHeight = Math.max(this.inputAreaTarget.scrollHeight, this.inputAreaBaseHeight);
    this.inputAreaTarget.style.height = `${newHeight}px`;
  }
}
