import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="atleast-one"
export default class extends Controller {
  static targets = [ "toShow", "toEnable", "isChecked", "hasSelected", "hasChanged" ];

  toggle() {
    let is_one_active = this.checked() || this.selected() || this.changed();

    if (is_one_active) {
      this.activate();
    } else {
      this.disable()
    }
  }

  checked() {
    let is_one_active = false;
    this.isCheckedTargets.forEach(function(element) {
      if (element.checked) {
        is_one_active = true;
      }
    })
    return is_one_active;
  }

  selected() {
    let is_one_active = false;
    this.hasSelectedTargets.forEach(function(element) {
      if (element.value != '') {
        is_one_active = true;
      }
    })
    return is_one_active;
  }

  changed() {
    let is_one_active = false;
    this.hasChangedTargets.forEach(function(element) {
      if (element.defaultValue != element.value) {
        is_one_active = true;
      }
    })
    return is_one_active;
  }

  activate() {
    this.toEnableTargets.forEach(function(element) {
        element.disabled = false;
      })
    this.toShowTargets.forEach(function(element) {
      element.classList.toggle('d-none');
    })
  }

  disable() {
    this.toShowTargets.forEach(function(element) {
        if (!element.classList.contains('d-none')) {
          element.classList.toggle('d-none')
        }
    })
    this.toEnableTargets.forEach(function(element) {
      element.disabled = true;
    })
  }
}
