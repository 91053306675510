import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "tbody" ]
  static values = { count: Number, url: String }

  remove() {
    var lineCount = $( this.tbodyTarget ).find('tr').length
    if (lineCount > 1) {
      event.currentTarget.closest('tr').remove();
    };
  }

  add() {
    this.countValue++;
    //console.log(this.countValue)
    fetch(`${this.urlValue}?count=${this.countValue}`)
      .then(response => response.text())
      .then(html => this.tbodyTarget.insertAdjacentHTML('beforeend', html))
  }
}
