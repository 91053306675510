import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'form',
    'iban',
    'accountToken',
    'bankAccountToken',
    'credentials',
    'businessType',
    'submit',
    'refresh',
    'errors',
    'stripeMessage',
    'waitMessages',
  ];

  generateAccountToken(stripe) {
    const data = {
      business_type: this.businessTypeTarget.dataset.businessType,
      tos_shown_and_accepted: true,
    };

    return stripe.createToken('account', data);
  }

  generateBankAccountToken(stripe) {
    const data = {
      country: 'FR',
      currency: 'eur',
      account_number: this.ibanTarget.value.replace(/\s/g, ''),
    };

    return stripe.createToken('bank_account', data);
  }

  handleFormSubmit(event) {
    event.preventDefault();

    if (!this.ibanTarget.value) {
      return;
    }

    const stripe = Stripe(this.credentialsTarget.dataset.stripePublishableKey);

    // Disable submit button
    this.submitTarget.disabled = true;
    // Show refresh icon
    this.refreshTarget.style.display = 'block';
    // Hide errors
    this.stripeMessageTarget.style.display = 'none';
    this.errorsTarget.style.display = 'none';
    // Show wait messages
    this.waitMessagesTarget.style.display = 'block';

    this.generateAccountToken(stripe)
      .then((result) => {
        if (result.token) {
          this.accountTokenTarget.value = result.token.id;
          return this.generateBankAccountToken(stripe);
        } else {
          throw result.error.message;
        }
      })
      .then((result) => {
        if (result.token) {
          this.bankAccountTokenTarget.value = result.token.id;
          this.formTarget.submit();
        } else {
          throw result.error.message;
        }
      })
      .catch((error) => {
        // Re-enable submit buttons
        this.submitTarget.disabled = false;
        // Hide refresh icon
        this.refreshTarget.style.display = 'none';
        // Hide wait messages
        this.waitMessagesTarget.style.display = 'none';
        // Set error message
        this.stripeMessageTarget.innerText = error;
        this.stripeMessageTarget.style.display = 'block';
        // Show errors
        this.errorsTarget.style.display = 'block';
      });
  }
}
