import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="turbo-form"
export default class extends Controller {
  static targets = ['form'];
  connect() {
    this.timeout = null;
  }

  submit(event) {
    let milliseconds = event.params.timeout ?? 0;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (event.params.closestform) {
        this.submitForm(event.target.closest('form'), event.params);
      } else {
        this.formTargets.forEach((form) => {
          this.submitForm(form, event.params);
        });
      }
    }, milliseconds);
  }

  refresh_page(event) {
    if (event.detail.success) {
      const fetchResponse = event.detail.fetchResponse;

      window.location = fetchResponse.response.url;
    }
  }

  submitForm(form, params) {
    if (params.confirm) {
      if (!confirm(params.confirm)) {
        return;
      }
    }
    if (typeof form.requestSubmit === 'function') {
      form.requestSubmit();
    } else {
      var submitter = document.createElement('input');
      submitter.type = 'submit';
      submitter.hidden = true;
      form.appendChild(submitter);
      submitter.click();
      form.removeChild(submitter);
    }
  }
}
