import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--oracle--columns"
export default class ColumnsControler extends Controller {

  connect() {
    this.initialize_columns();
    document.addEventListener('turbo:frame-load', this.initialize_columns);
  }

  initialize_columns() {
    const inputs = document.querySelectorAll('.update-cols');
    for(let i = 0 ; i < inputs.length ; i++){
      if(!inputs[i].checked) {
        ColumnsControler.toggleColumn(inputs[i]);
      }
    }
  }

  update(event) {
    ColumnsControler.toggleColumn(event.currentTarget);
  }

  static toggleColumn(inputChk) {
    const target = inputChk.getAttribute('data-target');
    const colIndex = document.getElementById('column-' + target).cellIndex + 1;
    document.querySelectorAll(`td:nth-child(${colIndex}),th:nth-child(${colIndex})`).forEach(function (item) {
      item.classList.toggle('d-none');
    });
  }

}
