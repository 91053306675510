import { Controller } from '@hotwired/stimulus';

function sumOfFields(fields) {
  return fields.reduce((sum, field) => {
    if (field.offsetParent !== null) {
      const value = Number.isNaN(field.valueAsNumber) ? 0 : field.valueAsNumber;
      return sum + Math.round(value * 100);
    }
    return sum;
  }, 0);
}

export default class extends Controller {
  static targets = ['debit', 'credit', 'row', 'totalRow', 'totalDebit', 'totalCredit'];

  connect() {
    this.formatter = Intl.NumberFormat(undefined, { style: 'currency', currency: 'EUR' });

    this.rowTargets.forEach((row) => this.toggleDebitCreditForRow(row));
    this.updateTotalRowColor();
  }

  toggleDebitCredit(event) {
    const row = event.target.closest('tr');

    this.toggleDebitCreditForRow(row);
    this.updateTotalRowColor();
  }

  toggleDebitCreditForRow(row) {
    const debitField = row.querySelector('[data-syndic--balance-entries-target="debit"]');
    const creditField = row.querySelector('[data-syndic--balance-entries-target="credit"]');

    if (debitField.valueAsNumber < 0) {
      debitField.value = '';
    }
    if (creditField.valueAsNumber < 0) {
      creditField.value = '';
    }

    const hasDebit = debitField.valueAsNumber > 0;
    const hasCredit = creditField.valueAsNumber > 0;

    debitField.disabled = hasCredit;
    creditField.disabled = hasDebit;
  }

  updateTotalRowColor() {
    const totalDebit = sumOfFields(this.debitTargets);
    const totalCredit = sumOfFields(this.creditTargets);

    this.totalDebitTarget.textContent = this.formatter.format(totalDebit / 100);
    this.totalCreditTarget.textContent = this.formatter.format(totalCredit / 100);

    if (totalDebit !== totalCredit) {
      this.totalRowTarget.classList.add('table-danger', 'text-black');
    } else {
      this.totalRowTarget.classList.remove('table-danger', 'text-black');
    }
  }
}
