/* This controller is used to toggle the class and text of an element.
  It uses the following data attributes:
  data-initialclass, data-toggleclass, data-initialtext, data-toggletext
  - data-initialclass: the initial class of the element
  - data-toggleclass: the class to toggle
  - data-initialtext: the initial text of the element
  - data-toggletext: the text to toggle */
/* Example:
 - file name : _landlord_contract_amendment_form.html.haml(l:29)
  %div{ data: { controller: 'generic-toggle' } }
    .row.row-productivity
      .col.d-flex.justify-content-end.align-items-center
        %btn.btn.btn-info{ data: { action: 'click->generic-toggle#handleElementEventToggleAndHTML', initialClass: 'btn-info', toggleClass: 'btn-danger' } }
          %i.bi.bi-plus-lg.pr-2{data: { generic_toggle_target: 'elementToToggle', initialClass: 'bi-plus-lg', toggleClass: 'bi-x-lg' }}
          %span{data: { generic_toggle_target: 'elementToToggle', initialText: 'Ajouter un avenant déjà signé', toggleText: 'Annuler' }} Ajouter un avenant déjà signé
    .landlord-contract-amendment-form.d-none{ data: { controller: 'form-toggle', generic_toggle_target: 'elementToToggle', initialClass: 'd-none', toggleClass: 'd-block' } }

  -- It is important that the div with the generic-toggle data controller encloses everything that needs to be toggled.
 */

/* How it Work ?
  The function handleElementEventToggleAndHTML will initialize the dataset of the event target,
  then it will toggle the class of the event target, and subsequently loop through all elementToToggleTargets
  to toggle the class and text of each element. */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['elementToToggle'];

  handleElementEventToggleAndHTML(event) {
    let dataset = this.#init(event.currentTarget);
    this.#addOrRemoveClass(event.currentTarget, dataset['initialClass'], dataset['toggleClass']);
    this.elementToToggleTargets.forEach((element) => {
      dataset = this.#init(element);
      if (dataset['initialText'] && dataset['toggleText']) {
        this.#toggleInnerText(element, dataset['initialText'], dataset['toggleText']);
      }
      if (dataset['initialClass'] && dataset['toggleClass']) {
        this.#addOrRemoveClass(element, dataset['initialClass'], dataset['toggleClass']);
      }
    });
  }

  #toggleInnerText(element, initialText, toggleText) {
    if (element.innerHTML?.trim() === initialText) {
      element.innerHTML = toggleText;
    } else {
      element.innerHTML = initialText;
    }
  }

  #addOrRemoveClass(element, initialClass, toggleClass) {
    if (element.classList.contains(initialClass)) {
      element.classList.remove(initialClass);
      element.classList.add(toggleClass);
    } else if (element.classList.contains(toggleClass)) {
      element.classList.add(initialClass);
      element.classList.remove(toggleClass);
    }
  }

  #init(element) {
    const dataset = {
      initialClass: element.dataset.initialclass,
      toggleClass: element.dataset.toggleclass,
      initialText: element.dataset.initialtext,
      toggleText: element.dataset.toggletext,
    };
    return dataset;
  }
}
