import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="form-toggle"
export default class extends Controller {
  static targets = [ "toToggle" ];

  toggle() {
    this.toToggleTargets.forEach(function(toToggle) {
      toToggle.classList.toggle('d-none');
      const isHide = toToggle.classList.contains('d-none');
      toToggle.querySelectorAll('input,textarea').forEach(function(input) {
        if (input.dataset.alwaysDisabled !== 'true') { input.disabled = isHide; }
      });
    })
  }
}
