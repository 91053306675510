import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="auth--password-validation"
export default class extends Controller {
  static targets = ['passwordField', 'passwordHelpers', 'eye'];

  isPasswordValid() {
    let password = this.passwordFieldTarget.value;
    let passwordField = this.passwordFieldTarget;
    let helpers = this.passwordHelpersTarget;
    let childHelpers = helpers.querySelectorAll('div');
    let state;
    let states = { lowerCase: 'invalid', upperCase: 'invalid', digit: 'invalid', symbol: 'invalid', limit: 'invalid' };
    passwordField.classList.add('is-invalid');
    helpers.classList.remove('d-none');
    childHelpers.forEach((helper) => {
      if (helper.className === 'lower-case') {
        state = 'lowerCase';
        this.updateState(helper, states, state, /[a-z]/.test(password));
      }
      if (helper.className === 'upper-case') {
        state = 'upperCase';
        this.updateState(helper, states, state, /[A-Z]/.test(password));
      }
      if (helper.className === 'digit') {
        state = 'digit';
        this.updateState(helper, states, state, /\d/.test(password));
      }
      if (helper.className === 'symbol') {
        state = 'symbol';
        this.updateState(helper, states, state, /[^A-Za-z0-9]/.test(password));
      }
      if (helper.className === 'limit') {
        state = 'limit';
        this.updateState(helper, states, state, /.{8,}/.test(password));
      }
    });
    let allValid = Object.values(states).every((state) => state === 'valid');
    if (passwordField.classList.contains('input-to-verify') || passwordField.classList.contains('input-form')) {
      this.validate_bootstrap_3(allValid, passwordField);
    } else {
      this.validate_bootstrap_5(allValid, passwordField);
    }
  }

  // use valid and invalid class
  validate_bootstrap_5(allValid, passwordField) {
    if (allValid) {
      passwordField.classList.remove('is-invalid');
      passwordField.classList.add('is-valid');
    } else {
      passwordField.classList.remove('is-valid');
      passwordField.classList.add('is-invalid');
    }
  }

  validate_bootstrap_3(allValid, passwordField) {
    if (allValid) {
      passwordField.classList.remove('is_invalid');
      passwordField.classList.add('is_valid');
    } else {
      passwordField.classList.remove('is_valid');
      passwordField.classList.add('is_invalid');
    }
  }
  updateState(element, states, state, condition) {
    if (condition === true) {
      const img = element.querySelector('img');
      img.setAttribute('src', 'https://flatlooker.s3.amazonaws.com/design-system/valid-symbol.svg');
      const txt = element.querySelector('span');
      txt.classList.remove('invalid-feedback');
      txt.classList.add('valid-feedback');
      states[state] = 'valid';
    } else {
      const img = element.querySelector('img');
      img.setAttribute('src', 'https://flatlooker.s3.amazonaws.com/design-system/fail-symbol.svg');
      const txt = element.querySelector('span');
      txt.classList.remove('valid-feedback');
      txt.classList.add('invalid-feedback');
      states[state] = 'invalid';
    }
  }

  togglePasswordVisibility() {
    const passwordField = this.passwordFieldTarget;
    const eye = this.eyeTarget;

    if (passwordField.type === 'password') {
      passwordField.type = 'text';
      eye.classList.remove('bi-eye');
      eye.classList.add('bi-eye-slash');
    } else {
      passwordField.type = 'password';
      eye.classList.remove('bi-eye-slash');
      eye.classList.add('bi-eye');
    }
  }

  hideHelpers(event) {
    let newFocusTarget = event.relatedTarget;
    if (newFocusTarget && !['submit', 'checkbox'].includes(newFocusTarget.type)) {
      this.passwordHelpersTarget.classList.add('d-none');
    }
  }
}
