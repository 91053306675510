import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="internal--flats--holder-fill"
export default class extends Controller {
  static targets = [
    'contractorsData',
    'inventoryAgentsData',
    'landlordData',
    'lookersData',
    'contractorSelectList',
    'inventoryAgentSelectList',
    'lookerSelectList',
    'companySelectRow',
    'inventoryAgentSelectRow',
    'lookerSelectRow',
    'companyNameRow',
    'companyReasonRow',
    'otherReasonRow',
    'holderForm',
    'CommonContactRows',
    'diagnoserSelectRow',
    'diagnoserSelectList',
  ];

  static values = {
    contractorsData: String,
    inventoryAgentsData: String,
    landlordData: String,
    lookersData: String,
    diagnosersData: String,
  };

  selectLandlord() {
    this.applyData(JSON.parse(this.landlordDataValue), true);
    this.resetFields();
    this.CommonContactRowsTarget.classList.remove('d-none');
  }

  selectContractor() {
    this.applyData(JSON.parse(this.contractorsDataValue)[this.contractorSelectListTarget.selectedIndex], true);
    this.resetFields();
    this.CommonContactRowsTarget.classList.remove('d-none');
    this.companyNameRowTarget.classList.remove('d-none');
    this.companyReasonRowTarget.classList.remove('d-none');
    this.companySelectRowTarget.classList.remove('d-none');
    document.getElementById('company-reason').setAttribute('required', true);
    document.getElementById('company-reason').disabled = false;
    document.getElementById('select-contract').setAttribute('required', true);
  }

  selectInventoryAgent() {
    this.applyData(JSON.parse(this.inventoryAgentsDataValue)[this.inventoryAgentSelectListTarget.selectedIndex], true);
    this.resetFields();
    this.CommonContactRowsTarget.classList.remove('d-none');
    this.companyNameRowTarget.classList.remove('d-none');
    this.companyReasonRowTarget.classList.remove('d-none');
    this.inventoryAgentSelectRowTarget.classList.remove('d-none');
    document.getElementById('company-reason').setAttribute('required', true);
    document.getElementById('company-reason').disabled = false;
    document.getElementById('select-inventory-agent').setAttribute('required', true);
  }

  selectLooker() {
    this.applyData(JSON.parse(this.lookersDataValue)[this.lookerSelectListTarget.selectedIndex], true);
    this.resetFields();
    this.CommonContactRowsTarget.classList.remove('d-none');
    this.companyNameRowTarget.classList.remove('d-none');
    this.companyReasonRowTarget.classList.remove('d-none');
    this.lookerSelectRowTarget.classList.remove('d-none');
    document.getElementById('company-reason').setAttribute('required', true);
    document.getElementById('company-reason').disabled = false;
    document.getElementById('select-looker').setAttribute('required', true);
  }

  selectDiagnoser() {
    this.applyData(JSON.parse(this.diagnosersDataValue)[this.diagnoserSelectListTarget.selectedIndex], true);
    this.resetFields();
    this.CommonContactRowsTarget.classList.remove('d-none');
    this.companyNameRowTarget.classList.remove('d-none');
    this.companyReasonRowTarget.classList.remove('d-none');
    this.diagnoserSelectRowTarget.classList.remove('d-none');
    document.getElementById('company-reason').disabled = false;
    document.getElementById('company-reason').value = 'Diagnostics';

    if (this.diagnoserSelectListTarget.value === '') {
      document.getElementById('company').removeAttribute('readonly');
      document.getElementById('physical_key_set_code_holder_name').removeAttribute('readonly');
      document.getElementById('physical_key_set_code_holder_email').removeAttribute('readonly');
      document.getElementById('physical_key_set_code_holder_phone_number').removeAttribute('readonly');
      document.getElementById('company').removeAttribute('disabled');
      document.getElementById('physical_key_set_code_holder_name').removeAttribute('disabled');
      document.getElementById('physical_key_set_code_holder_email').removeAttribute('disabled');
      document.getElementById('physical_key_set_code_holder_phone_number').removeAttribute('disabled');
    }
  }

  selectOther() {
    this.applyData(
      { user_id: null, holder_type: 'other', holder_name: '', holder_email: '', holder_phone_number: '', company: '' },
      false,
    );
    this.resetFields();
    this.otherReasonRowTarget.classList.remove('d-none');
    document.getElementById('other-reason').disabled = false;
    document.getElementById('other-reason').setAttribute('required', true);
  }

  applyData(element, readonly) {
    Object.entries(element).forEach((entry) => {
      const [key, value] = entry;
      element = document.getElementById('physical_key_set_code_' + key);
      element ||= this.holderFormTarget.elements.namedItem(key);
      if (element) {
        readonly ? element.setAttribute('readonly', true) : element.removeAttribute('readonly');
        element.value = value;
      }
    });
  }

  resetFields() {
    this.CommonContactRowsTarget.classList.remove('d-none');

    this.companyNameRowTarget.classList.add('d-none');

    this.companySelectRowTarget.classList.add('d-none');
    this.inventoryAgentSelectRowTarget.classList.add('d-none');
    this.lookerSelectRowTarget.classList.add('d-none');
    this.diagnoserSelectRowTarget.classList.add('d-none');

    this.companyReasonRowTarget.classList.add('d-none');
    this.otherReasonRowTarget.classList.add('d-none');

    document.getElementById('company-reason').disabled = true;
    document.getElementById('other-reason').disabled = true;
    document.getElementById('company-reason').removeAttribute('required');
    document.getElementById('other-reason').removeAttribute('required');
    document.getElementById('select-looker').removeAttribute('required');
    document.getElementById('select-inventory-agent').removeAttribute('required');
    document.getElementById('select-contract').removeAttribute('required');
    document.getElementById('company-reason').value = null;
  }
}
