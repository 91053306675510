import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['selector', 'list'];

  connect() {
    this.listTarget.style = 'height: 130px;';
  }

  toggleDepartments(event) {
    if (event.target.value == 'false') {
      this.selectorTarget.classList.add('d-none');
      this.listTarget.required = false;
    } else {
      this.selectorTarget.classList.remove('d-none');
      this.listTarget.required = true;
    }
  }
}
