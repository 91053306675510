import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="syndic--distribution-key"e
export default class extends Controller {
  static targets = [
    'share',
    'totalShare',
    'shareWarning',
    'duplicateLotsWarning',
    'submit',
    'lotSelect',
    'shareWarningText',
  ];

  connect() {
    this.check();
  }

  check() {
    let checkShares = this.checkShares();
    let checkDuplicateLots = this.checkDuplicateLots();
    this.submitTarget.disabled = checkShares || checkDuplicateLots;
  }

  lotSelectTargetConnected() {
    this.check();
  }

  lotSelectTargetDisconnected() {
    this.check();
  }

  // private

  checkShares() {
    const totalShares = parseInt(this.totalShareTarget.value);

    const distributionKeyShare = this.shareTargets.reduce((acc, share) => {
      const shareValue = parseInt(share.value, 10);
      return acc + (isNaN(shareValue) ? 0 : shareValue);
    }, 0);

    if (totalShares && totalShares !== distributionKeyShare) {
      this.shareWarningTextTarget.textContent = `La somme des tantièmes doit être égale à ${totalShares}, elle est actuellement de ${distributionKeyShare}.`;
      this.shareWarningTarget.classList.remove('d-none');
      return true;
    } else {
      this.shareWarningTarget.classList.add('d-none');
      return false;
    }
  }

  checkDuplicateLots() {
    const lotIds = this.lotSelectTargets.map((select) => select.value);
    const uniqueLotIds = new Set(lotIds);

    if (lotIds.length !== uniqueLotIds.size) {
      this.duplicateLotsWarningTarget.classList.remove('d-none');
      return true;
    } else {
      this.duplicateLotsWarningTarget.classList.add('d-none');
      return false;
    }
  }
}
