import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="landlord--frame-updater"
export default class extends Controller {
  static targets = ['interlocutorsPopup', 'menuItem', 'mobilePageTitle', 'navbarTopMobile', 'offcanvas', 'pageContent'];
  static outlets = ['beacon-requests'];

  connect() {
    this.updateMobileHeader(document);

    document.addEventListener('turbo:before-fetch-request', (event) => {
      if (this.targetIsMainContentFrame(event)) {
        this.closeOffcanvases();
        if (this.hasBeaconRequestsOutlet) {
          this.beaconRequestsOutlet.sendBeacon();
        }
      }
    });

    document.addEventListener('turbo:before-frame-render', (event) => {
      if (this.targetIsMainContentFrame(event)) {
        this.updateActiveMenuLink();
        this.updateMobileHeader(event.detail.newFrame);
      }
    });

    document.addEventListener('turbo:frame-render', (event) => {
      if (this.targetIsMainContentFrame(event)) {
        this.scrollToTop();
      }
    });
  }

  targetIsMainContentFrame = (event) => event.target.id === 'landlord_v2_page_content';

  updateActiveMenuLink() {
    this.menuItemTargets.forEach((menuItem) => {
      let mode = menuItem.dataset.activeMode;
      let path = menuItem.dataset.activePath;
      if (!mode || !path) return;
      if (
        (mode === 'exact' && window.location.pathname === path) ||
        (mode === 'prefix' && window.location.pathname.startsWith(path))
      ) {
        menuItem.classList.add('link-primary');
        menuItem.classList.remove('link-gray');
      } else {
        menuItem.classList.add('link-gray');
        menuItem.classList.remove('link-primary');
      }
    });
  }

  updateMobileHeader(newFrame) {
    this.setMobileTitle(newFrame);

    if (window.getComputedStyle(this.navbarTopMobileTarget).display === 'none') return;

    this.setMobileHeaderBackgroundImage(newFrame);
    this.updateInterlocutors(newFrame);
  }

  setMobileTitle(newFrame) {
    let title = newFrame.querySelector('#page-title-mobile')?.innerHTML;
    if (title) {
      this.mobilePageTitleTarget.innerHTML = title;
    }
  }

  setMobileHeaderBackgroundImage(newFrame) {
    let navbarTopBackgroundImage =
      newFrame.querySelector('[data-navbar-top-background-image]')?.dataset.navbarTopBackgroundImage || 'none';
    this.navbarTopMobileTarget.style.setProperty('--background-image', navbarTopBackgroundImage);
  }

  updateInterlocutors(newFrame) {
    let inactiveInterlocutors = this.navbarTopMobileTarget.querySelectorAll('img.grayed-out');
    if (newFrame.querySelector('[data-single-interlocutor]')) {
      inactiveInterlocutors.forEach((img) => (img.style.display = 'none'));
    } else {
      inactiveInterlocutors.forEach((img) => (img.style.display = 'unset'));
    }
  }

  closeOffcanvases() {
    if (this.hasInterlocutorsPopupTarget) {
      this.interlocutorsPopupTarget.classList.remove('visible-popup');
    }
    this.offcanvasTargets.forEach((offcanvas) => {
      bootstrap.Offcanvas.getInstance(offcanvas)?.hide();
    });
  }

  scrollToTop() {
    this.pageContentTarget.scrollTop !== 0
      ? (this.pageContentTarget.scrollTop = 0)
      : this.pageContentTarget.scrollIntoView();
  }
}
