import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="admin--support-request--create-node
export default class extends Controller {
  static values = { nodeId: Number };
  static targets = ['checkbox', 'form'];
  static outlets = ['admin--support-request--step3', 'admin--support-request--form-elements-fields'];

  connect() {
    this.isConditional();
  }

  isConditional() {
    this.adminSupportRequestStep3Outlet.updateAddNode(this.checkboxTarget.checked);
  }

  setNextNodeId(nextNodeId) {
    this.adminSupportRequestFormElementsFieldsOutlets.forEach((outlet) => {
      outlet.setNextNodeId(nextNodeId);
    });
  }

  submitForm() {
    this.formTarget.requestSubmit();
  }

  adminSupportRequestFormElementsFieldsOutletConnected() {
    if (this.adminSupportRequestFormElementsFieldsOutlets.length > 0) {
      this.adminSupportRequestStep3Outlet.submitButtonTarget.disabled = false;
      this.adminSupportRequestStep3Outlet.submitButtonTarget.parentElement.setAttribute('data-bs-original-title', '');
    }
  }

  onDelete(event) {
    let confirmed = confirm('Voulez-vous vraiment supprimer cette question ?');

    if (!confirmed) {
      event.preventDefault();
    }

    document.getElementById(`create-node-${this.nodeIdValue}`).remove();
  }

  checkValidity() {
    return this.formTarget.checkValidity();
  }

  reportValidity() {
    this.formTarget.reportValidity();
  }

  updateCheckbox(disabled) {
    this.checkboxTarget.disabled = disabled;
  }
}
