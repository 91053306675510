import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--oracle"
export default class OracleControler extends Controller {
  disabledCanvasTransition() {
    Array.from(document.getElementsByClassName('flat-column')).forEach(function(item) {
      item.classList.remove('flat-column');
      item.dataset.action = null;
    });
  }
}
