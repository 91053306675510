import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="internal--flats--pending-end-date"
export default class extends Controller {
  static targets = ['pendingEndDate'];

  setRequiredAttribute(event) {
    // all pending reasons require an end date except for 'main_flat_or_rooms_are_rented'
    this.pendingEndDateTarget.required = event.target.value !== 'main_flat_or_rooms_are_rented';
  }
}
