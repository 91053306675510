import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="landlord--offcanvas"
export default class extends Controller {
  static targets = ['menuItem', 'pageContent', 'toggle'];
  static classes = ['active', 'inactive'];
  static activeMenuItems = [];

  connect() {
    document.querySelector('#navbar-left [data-bs-toggle="offcanvas"]').setAttribute('data-bs-target', '#hub');
    document.querySelector('#navbar-bottom [data-bs-toggle="offcanvas"]').setAttribute('data-bs-target', '#hub-mobile');
  }

  showOffcanvas() {
    this.pageContentTarget.style.filter = 'blur(4px)';
    this.activeMenuItems = this.menuItemTargets.filter((menuItem) => menuItem.classList.contains(this.activeClass));
    this.toggleTargets.forEach((toggle) => {
      toggle.classList.add(this.activeClass);
      toggle.classList.remove(this.inactiveClass);
    });
    this.activeMenuItems.forEach((activeMenuItem) => {
      activeMenuItem.classList.add(this.inactiveClass);
      activeMenuItem.classList.remove(this.activeClass);
    });
  }

  hideOffcanvas() {
    this.pageContentTarget.style.removeProperty('filter');
    this.toggleTargets.forEach((toggle) => {
      toggle.classList.add(this.inactiveClass);
      toggle.classList.remove(this.activeClass);
    });
    this.activeMenuItems.forEach((activeMenuItem) => {
      activeMenuItem.classList.add(this.activeClass);
      activeMenuItem.classList.remove(this.inactiveClass);
    });
  }

  contactUs(event) {
    let target = event.target.closest('#hub')
      ? '.open-interlocutors-list-link'
      : '.open-interlocutors-list-link-mobile';
    document.querySelectorAll(target)[0].click();
  }
}
