import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="internal--flats--publish-flat-step1"
export default class extends Controller {
  static targets = ['form', 'trigger', 'target', 'unknownConstructionYear'];
  connect() {
    this.setButtonState();
  }

  toggleButton() {
    this.setButtonState();
  }

  setButtonState() {
    const unknownConstructionYear = this.hasUnknownConstructionYearTarget ? this.unknownConstructionYearTarget : null;
    const trigger = this.triggerTarget;
    const target = this.targetTarget;

    if (unknownConstructionYear) {
      const isDisabled = unknownConstructionYear.value || !trigger.checked;
      target.disabled = isDisabled;
    } else {
      target.disabled = !trigger.checked;
    }
  }

  submit(event) {
    this.formTargets.every(form => {
      if (form.checkValidity()) {
        form.requestSubmit();
        return true;
      } else {
        form.reportValidity();
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        return false;
      }
    });
  }
}
