import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="internal--flats--photos-selection"
export default class extends Controller {
    static targets = [ "toToggleOpacity", "toHide" ]


    toggleVisiblePhotos() {
        this.toToggleOpacityTargets.forEach(function(toToggle) {
            toToggle.classList.toggle('opacity-50');
        });
    }
}
