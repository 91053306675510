import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="internal--flats--tenant-application-actions"
export default class extends Controller {
  static targets = ['row'];

  accept({ params: { url } }) {
    let row = this.rowTarget;
    let callbackFetch = () => {
      let all_applicants = document.getElementById('all_applicants');
      all_applicants.reload();
      row.classList.add('table-success');
      setTimeout(function () {
        row.remove();
      }, 1000);
    };

    this.call(url, callbackFetch);
  }

  reject({ params: { url } }) {
    let closestFrameToReload = this.element.closest('turbo-frame');
    let callbackFetch = () => {
      closestFrameToReload.reload();
    };

    this.call(url, callbackFetch);
  }

  preValidate(event) {
    const { url } = event.params;

    let callbackFetch = () => {
      event.target.classList.add('disabled');
    };

    this.call(url, callbackFetch);
  }

  //private
  call(url, callback) {
    const csrfToken = document.getElementsByName('csrf-token')[0].content;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/html',
        'X-CSRF-Token': csrfToken,
      },
    })
      .catch((e) => Rollbar.error(e))
      .then(callback);
  }
}
