import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="landlord-notifications"
export default class extends Controller {
  static targets = [ "notification" ]

  initialize(){
    this.intersectingIds = new Set();

    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    }
    let callback = ( entries, observer ) => {
      entries.forEach( entry => {
        if (entry.isIntersecting){
          this.intersectingIds.add(entry.target.dataset.notificationId)
        }
      });
      this.dispatchIntersectionEvent();
    };

    this.observer = new IntersectionObserver(callback, options);
  }

  notificationTargetConnected(element){
    if (element.dataset.isRead === 'false'){
      this.observer.observe(element);
    }
  }

  notificationTargetDisconnected(element){
    if (element.dataset.isRead === 'false'){
      this.observer.unobserve(element);
    }
  }

  disconnect(){
    this.observer.disconnect();
  }

  dispatchIntersectionEvent(){
    if (this.intersectingIds.size === 0) return;

    this.dispatch('intersection', { detail: { 'notification_ids[]': Array.from(this.intersectingIds) } });
  }
}
