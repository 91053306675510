import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="internal--applicant-show"
export default class extends Controller {
  static targets = [
    'guarantorTenantRelation',
    'isGuarantor',
    'guaranteeForm',
    'legalEntity',
    'personnalInformation',
    'guaranteeType',
    'nameInput',
    'form',
    'emailInput',
  ];

  connect() {
    this.applicantNature();
  }

  applicantNature() {
    if (this.isGuarantorTarget.value === 'true' && this.legalEntityTarget.value === 'true') {
      if (this.guaranteeTypeTarget.value == '') {
        this.guarantorTenantRelationTarget.classList.remove('d-none');
        document.getElementById('society').after( this.guarantorTenantRelationTarget);
        this.enableNameInputs();
      } else {
        this.guarantorTenantRelationTarget.classList.add('d-none');
        this.disableNameInputs();
      }
      this.guaranteeFormTarget.classList.remove('d-none');
    } else if (this.isGuarantorTarget.value === 'true' && this.legalEntityTarget.value === 'false') {
      this.guarantorTenantRelationTarget.classList.remove('d-none');
      document.getElementById('individual').after( this.guarantorTenantRelationTarget);
      this.guaranteeFormTarget.classList.add('d-none');
      this.enableNameInputs();
    } else {
      this.enableNameInputs();
      this.guarantorTenantRelationTarget.classList.add('d-none');
      this.guaranteeFormTarget.classList.add('d-none');
    }

    if (this.legalEntityTarget.value === 'true') {
      this.personnalInformationTarget.classList.add('d-none');
      this.emailInputTarget.disabled = true;
    } else {
      this.personnalInformationTarget.classList.remove('d-none');
      this.emailInputTarget.disabled = false;
    }
  }

  closeCanvas() {
    let unvalidForms = false;
    this.formTarget.querySelectorAll("[required]").forEach(function(input) {
      unvalidForms ||= !input.value;
    });
    if (!unvalidForms) {
      const event = new CustomEvent('close-canvas');
      window.dispatchEvent(event);
    }
  }

  // private

  disableNameInputs() {
    this.nameInputTargets.forEach( function(input) {
      input.disabled = true;
    });
  }

  enableNameInputs() {
    this.nameInputTargets.forEach( function(input) {
      input.disabled = false;
    });
  }
}
