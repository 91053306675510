import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="internal--payment-requests"
export default class extends Controller {
  static targets = [
    'form',
    'amountOffered',
    'amountBilled',
    'amountToPush',
    'vatRateToPush',
    'isRecurring',
    'dates',
    'textRecap',
    'startDate',
    'endDate',
    'frequency',
    'isActive',
  ];
  static values = { tenant: Boolean };

  connect() {
    this.setTextRecap();
  }

  disableAmountBilled() {
    this.amountBilledTarget.disabled = this.amountOfferedTarget.value !== '';
    this.amountToPushTarget.value = -this.amountOfferedTarget.value;
    this.setTextRecap();
  }
  disableAmountOffered() {
    this.amountOfferedTarget.disabled = this.amountBilledTarget.value !== '';
    this.amountToPushTarget.value = this.amountBilledTarget.value;
    this.setTextRecap();
  }

  setTextRecap() {
    if (this.amountOfferedTarget.value === '' && this.amountBilledTarget.value === '') {
      this.textRecapTarget.classList.add('d-none');
      return;
    }

    this.textRecapTarget.classList.remove('d-none');
    let text = `Le ${this.tenantValue ? 'locataire' : 'propriétaire'} sera `;
    if (this.amountToPushTarget.value > 0) {
      text += 'facturé';
      this.textRecapTarget.classList.add('text-danger');
      this.textRecapTarget.classList.remove('text-success');
    } else {
      text += 'remboursé';
      this.textRecapTarget.classList.add('text-success');
      this.textRecapTarget.classList.remove('text-danger');
    }

    let vatRateToPush = 0;
    if (this.hasVatRateToPushTarget) {
      vatRateToPush = Math.abs(this.vatRateToPushTarget.value);
    }
    const amount = Math.abs(this.amountToPushTarget.value);
    let amountToPrint = 0;
    if (vatRateToPush > 0) {
      const vat = amount * (vatRateToPush / 100.0);
      amountToPrint = parseFloat(amount + vat).toFixed(2);
    } else {
      amountToPrint = amount;
    }

    if (this.isRecurringTarget.value === 'true' || this.isRecurringTarget.checked) {
      let frequency = '0';
      this.frequencyTargets.forEach((radio) => {
        if (radio.checked) {
          frequency = radio.value;
        }
      });

      if (frequency === '0') {
        text += ` de ${amountToPrint}€`;
      } else if (frequency === '1') {
        text += ` mensuellement de ${amountToPrint}€`;
      } else if (frequency === '3') {
        text += ` trimestriellement de ${amountToPrint}€`;
      } else if (frequency === '6') {
        text += ` semestriellement de ${amountToPrint}€`;
      } else if (frequency === '12') {
        text += ` annuellement de ${amountToPrint}€`;
      }
    } else {
      text += ` une seule fois de ${amountToPrint}€`;
    }

    if (this.isRecurringTarget.value === 'true' || this.isRecurringTarget.checked) {
      text += ` à partir du ${this.startDateTarget.value.split("-").reverse().join('/')}`;
      if (this.endDateTarget.value !== '') {
        text += ` jusqu\'au ${this.endDateTarget.value.split("-").reverse().join('/')}`;
      }
    }
    text += '.';
    this.textRecapTarget.innerHTML = text;
  }

  toggleActive(event) {
    event.target.classList.add('opacity-75');
    event.target.classList.add('pe-none');
    this.isActiveTarget.value = this.isActiveTarget.value === 'true' ? 'false' : 'true';
    this.formTarget.requestSubmit()
  }
}
