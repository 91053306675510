import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

// Connects to data-controller="show-modal-with-id"
export default class extends Controller {
  static targets = ['modal'];

  connect() {
    if (window.location.href.includes(`#${this.modalTarget.id}`)) {
      let myModal = new Modal(this.modalTarget);
      myModal.show();
    }
  }
}
