import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="dark-light-modes"
export default class extends Controller {
  static targets = ['favicon'];

  connect() {
    const isDark = window.matchMedia('(prefers-color-scheme: dark)');
    if (isDark.matches) {
      this.faviconTarget.href = this.faviconTarget.href.replace('favicon-manda', 'favicon-manda-white');
    }
  }
}
