import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="internal--top-bar"
export default class extends Controller {
  static targets = [ 'navItem', 'navSubItem' ]

  clickOnLink(event) {
    this.changeActiveLink(event.currentTarget, this.navItemTargets)
  }

  clickOnSubLink(event) {
    this.changeActiveLink(event.currentTarget, this.navSubItemTargets)
  }

  changeActiveLink(target, items) {
    items.forEach(function(item) {
      item.classList.remove('active');
    });
    target.classList.add('active');
  }
}
