import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="internal--gli-ratio"
// The number_field expects to sent the value of the rent
//    data: { rent: @flat.rent, action: 'change->internal--gli-ratio#change' }
// The label associated to the number_field should be initialized in place. This controller only updates the value
//    = label_tag :gli_label, "Soit #{(f.object.send(:minimum_applications_income).to_f/@flat.rent).round(1)} GLI",
//      data: { 'internal--gli-ratio-target': 'gliLabel'}

export default class extends Controller {
  static targets = [ 'form', 'gliLabel', 'gliRequestValue' ]

  refreshGLIRatio(){
    const minimum_applications_income = parseFloat(this.gliRequestValueTarget.value);
    const expected_revenue = parseFloat(this.gliRequestValueTarget.dataset.expected);

    if (isNaN(minimum_applications_income) || isNaN(expected_revenue)) { return; }
    this.gliLabelTarget.innerText = 'Soit ' + (minimum_applications_income / expected_revenue).toFixed(1) + ' GLI';
  }
}
