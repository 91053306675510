import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="mobile-app-event" to send events to the mobile app
export default class extends Controller {
  static targets = ['trigger'];
  static values = {
    triggerListenerEvent: String,
    eventName: String,
  };

  connect() {
    if (!window.isMobileApp) {
      return;
    }

    this.connectEventListener();
  }

  // private

  connectEventListener() {
    this.triggerTarget.addEventListener(this.triggerListenerEventValue, () => {
      window.ReactNativeWebView.postMessage(JSON.stringify({ eventName: this.eventNameValue }));
    });
  }
}
