import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="user--tenant-payments"
export default class extends Controller {
  static targets = ['cardBody', 'topArrow', 'bottomArrow', 'hintBody'];
  static values = { expectedFirstRent: Boolean };

  connect() {
    this.displayPaymentsSection();
  }

  displayPaymentsSection() {
    if (this.expectedFirstRentValue) {
      this.cardBodyTarget.classList.remove('d-none');
    }
  }

  showCardBody() {
    this.cardBodyTarget.classList.toggle('d-none');
    this.topArrowTarget.classList.toggle('d-none');
    this.bottomArrowTarget.classList.toggle('d-none');
  }

  showHint(event) {
    const opener = event.target.closest('.hint__opener');
    if (!opener) return;

    const hintName = Array.from(opener.classList).find((className) => {
      return className === 'sepa' || className === 'stripe';
    });
    const hintBody = this.hintBodyTargets.find((element) => {
      return element.classList.contains(hintName);
    });

    if (window.getComputedStyle(hintBody).display === 'none') {
      hintBody.style.display = 'block';
    } else {
      hintBody.style.display = 'none';
    }
  }

  populatePaymentsDetailsModal({ params: { paymentId } }) {
    fetch(`/user/rental/tenant_payment_details/${paymentId}`, {
      method: 'GET',
      headers: {
        Accept: 'text/html',
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
      .then((response) => response.text())
      .then((html) => (document.querySelector('#modalPaymentDetails .modal-content').innerHTML = html))
      .catch((e) => {
        console.log(e);
        console.error('error xhr', e);
      });
  }
}
