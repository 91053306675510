import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="handover"
export default class extends Controller {
  static targets = ['excessiveRentComment', 'textArea'];

  connect() {
    this.#toggleClassBasedOnCondition(this.excessiveRentCommentTarget);
  }

  toggleExcessiveRentComment(event) {
    this.#toggleClassBasedOnCondition(event.currentTarget);
  }

  #toggleClassBasedOnCondition(element) {
    const isRentComplementPositive = element.dataset.rentComplement > 0;
    if (isRentComplementPositive) {
      this.excessiveRentCommentTarget.classList.remove('d-none');
    } else {
      this.excessiveRentCommentTarget.classList.add('d-none');
    }
    this.textAreaTarget.required = isRentComplementPositive;
  }
}
