import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['burgerButton', 'burgerMenu','burgerMenuChevron', 'burgerDropdown', 'hoverElement', 'changedElement', 'dropdownTrigger'];

  connect() {
    this.changedElementTargets.forEach((target) => {
      this.hoverElementTargets.forEach((element) => {
        element.addEventListener('mouseover', () => {
          target.classList.remove('d-none');
        });
        element.addEventListener('mouseout', () => {
          target.classList.add('d-none');
        });
      });
    });
  }

  toggleVisibility() {
    this.changedElementTarget.classList.toggle('d-none');
  }

  toggleBurgerMenu() {
    this.burgerMenuTarget.classList.toggle('d-none');
    const mainAlert = document.getElementsByClassName('main-alert');
    if (mainAlert.length > 0) this.handleBurgerMenuTopHeight(mainAlert);
    if (this.burgerMenuTarget.classList.contains('d-none')) {
      document.body.style.overflow = 'auto';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }

  handleBurgerMenuDropdown() {
    this.burgerDropdownTarget.classList.toggle('d-none');
    this.burgerMenuChevronTarget.classList.toggle('rotate-180');
    this.dropdownTriggerTarget.classList.toggle('dropdown-trigger-active');
  }

  handleBurgerMenuTopHeight(mainAlert) {
    this.burgerMenuTarget.style.top = `${mainAlert[0].offsetHeight + 80}px`;
  }
}
