import { Controller } from '@hotwired/stimulus';
import Choices from 'choices.js';
export default class extends Controller {
  static targets = [
    'templateForm',
    'bodyForm',
    'saveTemplateButton',
    'updateTemplateButton',
    'deleteTemplateButton',
    'templateInputs',
    'errorFeedback',
  ];
  static values = {
    templates: Array,
    postTemplateUrl: String,
    customOption: String,
  };

  connect() {
    this.formElements = this.templateFormTarget.elements;
    this.updateTemplateButtonTarget.hidden = true;
    this.deleteTemplateButtonTarget.hidden = true;
    this.errorFeedbackTarget.hidden = true;
    this.canvasFrame = document.getElementById('internal_canvas');
    if (this.canvasFrame.dataset.customOptions) {
      this.customOptions = JSON.parse(this.canvasFrame.dataset.customOptions);
      if (this.customOptions.email) {
        this.formElements.namedItem('to').value = this.customOptions.email;
      }
      if (this.customOptions.bcc) {
        this.formElements.namedItem('bcc').value = `${this.formElements.namedItem('bcc').value},${
          this.customOptions.bcc
        }`;
      }
    }
    let selectedValue = document.getElementById('template').value;
    if (selectedValue != '') {
      const selectedTemplate = this.templatesValue.find((t) => t.id == selectedValue);
      if (selectedTemplate.default_template) {
        this.swapDefaultTemplate(
          selectedTemplate.body,
          selectedTemplate.subject,
          JSON.stringify(selectedTemplate.attachments) ?? null,
        );
      } else if (this.customOptionValue == 'single-template') {
        this.applySingleTemplateBody(
          selectedTemplate.body,
          selectedTemplate.subject,
          JSON.stringify(selectedTemplate.attachments) ?? null,
        );
      } else {
        this.swapTemplateBody(
          selectedTemplate.body,
          selectedTemplate.subject,
          JSON.stringify(selectedTemplate.attachments) ?? null,
        );
      }
    }
    this.setSelecteEmails();
  }

  switchTemplate(event) {
    const selectedTemplate = this.templatesValue.find((t) => t.id == event.target.value);
    if (selectedTemplate === undefined) {
      this.initNewTemplateForm();
    } else {
      if (selectedTemplate.default_template) {
        this.swapDefaultTemplate(
          selectedTemplate.body,
          selectedTemplate.subject,
          JSON.stringify(selectedTemplate.attachments) ?? null,
        );
      } else {
        this.swapTemplateBody(
          selectedTemplate.body,
          selectedTemplate.subject,
          JSON.stringify(selectedTemplate.attachments) ?? null,
        );
      }
    }
  }

  deleteTemplate(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    this.templateRequest('DELETE');
  }

  createTemplate(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    this.templateRequest('POST');
  }

  updateTemplate(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    this.templateRequest('PATCH');
  }

  templateRequest(method) {
    const csrfToken = document.getElementsByName('csrf-token')[0].content;

    // We need to manually remove the tooltips because Bootstrap let them even when the element
    // it's attached to is removed from the DOM
    document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach((e) => {
      $(e).tooltip('hide');
    });

    fetch(this.formUrl(method), {
      method: method,
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(this.formContent(method)),
    })
      .then((rawResponse) => {
        if (rawResponse.ok) {
          return rawResponse.json();
        }

        return Promise.reject(rawResponse);
      })
      .then((response) => {
        const canvas = this.canvasFrame;
        canvas.addEventListener('turbo:frame-load', function autoSelectTemplate(event) {
          const newFormTemplate = event.target.getElementsByTagName('form')[0].elements.namedItem('template');
          if (response.template) {
            newFormTemplate.value = response.template.id;
          } else {
            newFormTemplate.selectedIndex = 0;
          }
          newFormTemplate.dispatchEvent(new Event('change'));
          canvas.removeEventListener('turbo:frame-load', autoSelectTemplate);
        });
        canvas.reload();
      })
      .catch((error) => {
        error.json().then(({ errors }) => {
          console.error(errors);
          this.errorFeedbackTarget.hidden = false;
        });
      });
  }

  // private

  formUrl(method) {
    const templateId = this.formElements.namedItem('template').value;

    return method === 'POST' ? this.postTemplateUrlValue : this.postTemplateUrlValue + '/' + templateId;
  }

  formContent(method) {
    const templateBody = this.bodyFormTarget.value;
    const templateSubject = this.formElements.namedItem('subject').value;
    const templateAttachments = this.formElements.namedItem('attachments').value;
    const templateName = this.formElements.namedItem('name').value;
    const templateTypeOfEmail = this.formElements.namedItem('type_of_email').value;
    const templateUserId = this.formElements.namedItem('user_id').value;
    const templateId = this.formElements.namedItem('template').value;

    if (method === 'POST') {
      return {
        template_email: {
          body: templateBody,
          name: templateName,
          type_of_email: templateTypeOfEmail,
          user_id: templateUserId,
          subject: templateSubject,
          attachments: templateAttachments,
        },
      };
    } else if (method === 'PATCH') {
      return {
        template_email: {
          body: templateBody,
          subject: templateSubject,
        },
      };
    } else if (method === 'DELETE') {
      return {
        template_email: {
          id: templateId,
        },
      };
    }
  }

  swapTemplateBody(body, subject, attachments) {
    this.bodyFormTarget.value = body;
    this.formElements.namedItem('subject').value = subject;
    this.formElements.namedItem('attachments').value = attachments;
    this.templateInputsTarget.hidden = true;
    this.saveTemplateButtonTarget.hidden = true;
    this.updateTemplateButtonTarget.hidden = false;
    this.deleteTemplateButtonTarget.hidden = false;
  }

  initNewTemplateForm() {
    this.bodyFormTarget.value = '';
    this.formElements.namedItem('subject').value = '';
    this.formElements.namedItem('attachments').value = '';
    this.updateTemplateButtonTarget.hidden = true;
    this.deleteTemplateButtonTarget.hidden = true;
    this.templateInputsTarget.hidden = false;
    this.saveTemplateButtonTarget.hidden = false;
  }

  swapDefaultTemplate(body, subject, attachments) {
    this.bodyFormTarget.value = body;
    this.formElements.namedItem('subject').value = subject;
    this.formElements.namedItem('attachments').value = attachments;
    this.templateInputsTarget.hidden = true;
    this.saveTemplateButtonTarget.hidden = true;
    this.updateTemplateButtonTarget.hidden = true;
    this.deleteTemplateButtonTarget.hidden = true;
  }

  applySingleTemplateBody(body, subject, attachments) {
    this.saveTemplateButtonTarget.hidden = true;
    this.bodyFormTarget.value = body;
    this.formElements.namedItem('subject').value = subject;
    this.formElements.namedItem('attachments').value = attachments;
  }

  setSelecteEmails() {
    const emailSelects = document.querySelectorAll('.email-select');
    emailSelects.forEach((emailSelect) => {
      if (emailSelect) {
        new Choices(emailSelect, {
          removeItemButton: true,
          maxItemCount: -1,
          duplicateItemsAllowed: false,
          addItems: true,
          paste: true,
          editItems: true,
          addItemFilter: (value) => {
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
          },
          itemAdded: function (value) {
            alert(`L'email ${value.value} a bien été ajouté`);
          },
          addItemText: (value) => {
            return `Appuyez sur Entrée pour ajouter l'email "${value}"`;
          },
          customAddItemText: 'Vous pouvez uniquement ajouter des adresses emails valides.',
          delimiter: ',',
        });
      }
    });
  }
}
