import { Controller } from '@hotwired/stimulus'
import { getCookie, setCookie } from '@helpers/cookie_helper'

export default class extends Controller {
  static targets = [ 'sidebar' ];

  connect() {
    if (getCookie('show_sidebar') === '1') {
      this.showSidebar();
    } else {
      this.hideSidebar();
    }
  }

  showSidebar() {
    this.sidebarTarget.classList.add('show');
    setCookie('show_sidebar', 1, 365 * 10);
  }

  hideSidebar() {
    this.sidebarTarget.classList.remove('show');
    setCookie('show_sidebar', 0, 365 * 10);
  }
}
