import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="internal--flats--right-panel"
export default class extends Controller {

  connect() {
    let validTimer;
    const form = document.getElementById("edit-handover-form");
    form.onsubmit = function() {
      document.getElementById('handover_comment_for_manager').classList.add('is-valid');
      clearTimeout(validTimer);
      validTimer = setTimeout(function () {
        document.getElementById('handover_comment_for_manager').classList.remove('is-valid');
      }, 3000)
    }
  }
}
