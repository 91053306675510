import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="admin--support-request--step3
export default class extends Controller {
  static outlets = ['admin--support-request--create-node', 'internal--canvas'];
  static targets = ['addNodeIndex', 'addNodeForm', 'nextStepForm', 'submitButton'];

  adminSupportRequestCreateNodeOutletConnected() {
    this.addNodeIndexTarget.value = parseInt(this.addNodeIndexTarget.value) + 1;
    this.updateAllCheckbox();
  }

  adminSupportRequestCreateNodeOutletDisconnected() {
    this.addNodeIndexTarget.value = parseInt(this.addNodeIndexTarget.value) - 1;
    this.updateAllCheckbox();
  }

  updateAddNode(checked) {
    if (checked) {
      this.addNodeFormTarget.classList.add('d-none');
    } else {
      this.addNodeFormTarget.classList.remove('d-none');
    }
  }

  async submit(event) {
    event.target.disabled = true;
    this.updateNodesIds();
    let allFormsValid = true;

    for (const outlet of this.adminSupportRequestCreateNodeOutlets) {
      if (!outlet.checkValidity()) {
        allFormsValid = false;
        outlet.reportValidity();
        break;
      }
    }

    if (allFormsValid) {
      const responses = await Promise.all(
        this.adminSupportRequestCreateNodeOutlets.map((outlet) =>
          fetch(outlet.formTarget.action, { method: outlet.formTarget.method, body: new FormData(outlet.formTarget) }),
        ),
      );

      const successfulResponses = responses.filter((response) => response.ok);

      if (successfulResponses.length === this.adminSupportRequestCreateNodeOutlets.length) {
        this.nextStepFormTarget.requestSubmit();
        this.internalCanvasOutlet.closeCanvas();
      } else {
        event.target.disabled = false;
      }
    } else {
      event.target.disabled = false;
    }
  }

  //private

  updateNodesIds() {
    if (this.adminSupportRequestCreateNodeOutlets.length > 0) {
      for (let i = 0; i < this.adminSupportRequestCreateNodeOutlets.length - 1; i++) {
        const nextNodeId = this.adminSupportRequestCreateNodeOutlets[i + 1].nodeIdValue;
        this.adminSupportRequestCreateNodeOutlets[i].setNextNodeId(nextNodeId);
      }
    }
  }

  updateAllCheckbox() {
    // this.adminSupportRequestCreateNodeOutlets.forEach((createNode, index) => {
    //   createNode.updateCheckbox(this.adminSupportRequestCreateNodeOutlets.length - 1 !== index);
    // });
    // Le code ci-dessus permet de désactiver la checkbox de tous les noeuds sauf le dernier, dans le cas d'une
    // question conditionnelle uniquement à la fin d'une suite de question non conditionnelle.
    // Pour l'instant ce n'est pas possible donc on doit faire la question conditionnelle en première.
    const updateTo = this.adminSupportRequestCreateNodeOutlets.length !== 1;
    this.adminSupportRequestCreateNodeOutlets.forEach((createNode) => {
      createNode.updateCheckbox(updateTo);
    });
  }
}
