import { Controller } from '@hotwired/stimulus';

// control a target attribute from a trigger attribute
// The triggerTarget is the element that will control the targetTarget
// You can specify which attribute you want to use as a trigger with the triggerAttributeValue
// And you can specify which attribute you want to target with the targetAttributeValue
export default class extends Controller {
  static targets = ['trigger', 'target', 'checkbox'];
  static values = {
    targetAttribute: 'disabled',
    triggerAttribute: 'checked',
    invertTriggerAttribute: true,
    toggleClass: String,
    loadOnConnect: true,
  };

  connect() {
    if (this.loadOnConnectValue || this.loadOnConnectValue === undefined) {
      this.setTargetFromTarget();
    }
  }

  toggle() {
    this.setTargetFromTarget();
  }

  // private

  setTargetFromTarget() {
    let new_value = this.triggerTarget[this.triggerAttributeValue];
    if (this.invertTriggerAttributeValue) {
      new_value = !new_value;
    }
    this.targetTarget[this.targetAttributeValue] = new_value;
    if (this.hasToggleClassValue) {
      this.targetTarget.classList.toggle(this.toggleClassValue, !new_value);
    }
    if (this.hasCheckboxTarget) {
      this.checkboxTarget.checked = new_value;
    }
  }
}
