import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="internal--send-tenant-contract"
export default class extends Controller {
  static targets = ['rowForm']

  connect() {
    document.querySelector("turbo-frame#contract_link")
      .addEventListener('turbo:frame-load', function () {
        document.getElementById('rowForm').classList.remove('d-none');
      });
  }
}