import { Controller } from '@hotwired/stimulus';

const EXCLUDED_INACTIVE_PANEL_PATHS = ['/internal/default_canvas', '/mandy/condos'];

export default class extends Controller {
  static targets = ['canvas'];
  static values = {
    defaultMenuUrl: String,
  };

  replaceCanvas(event) {
    const { location, customOptions, customConditions } = event.params;
    if (this.shouldSkipRendering(customConditions, event.target)) {
      return;
    }

    const stringified_options = JSON.stringify(customOptions);

    if (this.shouldCloseCanvas(location, stringified_options)) {
      this.closeCanvas(this.shouldRenderInactivePanel(customOptions));
    } else {
      if (customOptions) {
        this.canvasTarget.dataset.customOptions = stringified_options;
      }
      this.canvasTarget.src = location;
      this.switchPanel(customOptions, location);
    }
  }

  closeCanvas(shouldRenderInactivePanel) {
    this.canvasTarget.src = this.defaultMenuUrlValue;
    this.switchPanel({ shouldRenderInactivePanel }, location);

    if (EXCLUDED_INACTIVE_PANEL_PATHS.includes(this.defaultMenuUrlValue)) {
      this.updatePanelClass('');
    } else {
      this.updatePanelClass('inactive-panel');
    }
  }

  // private

  shouldSkipRendering(conditions, target) {
    return conditions?.includes('targetMustBeRow') && target.nodeName !== 'TD';
  }

  shouldCloseCanvas(location) {
    return this.canvasTarget.src?.endsWith(location);
  }

  updatePanelClass(className) {
    const panel = document.getElementsByClassName('panel')[0];
    panel.classList.remove('active-panel', 'big-active-panel', 'inactive-panel');
    if (className === '') {
      return;
    }

    panel.classList.add(className);
  }

  switchPanel(customOptions, location) {
    if (!location) {
      return;
    }

    if (
      customOptions &&
      Object.prototype.hasOwnProperty.call(customOptions, 'uploadId') &&
      customOptions.uploadId == 'empty'
    ) {
      this.updatePanelClass('inactive-panel');
    } else if (
      !location.href &&
      (this.canvasTarget.src?.endsWith(location) || this.canvasTarget.src == this.defaultMenuUrlValue)
    ) {
      if (customOptions?.bigWidth == 'true') {
        this.updatePanelClass('big-active-panel');
      } else {
        this.updatePanelClass('active-panel');
      }
    } else {
      if (!customOptions?.shouldRenderInactivePanel) {
        this.updatePanelClass('inactive-panel');
      } else {
        this.updatePanelClass('');
      }
    }
  }

  shouldRenderInactivePanel(conditions) {
    return conditions?.includes('skipInactivePanelRender');
  }
}
