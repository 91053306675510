import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="carousel"
export default class extends Controller {
  static targets = [ 'carousel', 'slide', 'buttonContainer' ]

  connect() {
    this.gap = parseInt(getComputedStyle(this.carouselTarget).gap) || 0;
    this.scrollLength = this.slideTarget.offsetWidth + this.gap;
    this.handleButtonsScroll(this.carouselTarget, this.buttonContainerTarget);
    addEventListener('resize', () => this.handleButtonsScroll(this.carouselTarget, this.buttonContainerTarget));
  }

  handleButtonsScroll(carousel, buttonContainer){
    let isOverflown = carousel.scrollWidth > carousel.clientWidth;
    if (isOverflown) {
      buttonContainer.classList.remove('d-none');
    } else {
      buttonContainer.classList.add('d-none');
    }
  }

  scrollLeft(){
    this.carouselTarget.scrollLeft -= this.scrollLength;
  }

  scrollRigth(){
    this.carouselTarget.scrollLeft += this.scrollLength;
  }
}
