import { Controller } from "@hotwired/stimulus"

// Connects to init popover
export default class extends Controller {
  connect() {
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl, {
        container: popoverTriggerEl,
        delay: { "show": 0, "hide": 100 }
      })
    })
    const allowList = bootstrap.Tooltip.Default.allowList;
    allowList['*'].push('data-controller');
    allowList['*'].push('data-action');
    allowList['*'].push('data-text-to-copy');
    allowList['*'].push('data-internal--canvas-custom-options-param');
    allowList['*'].push('data-internal--canvas-location-param');
  }
}
