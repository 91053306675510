import { Controller } from '@hotwired/stimulus';
import { debounce } from 'lodash-es';

export default class extends Controller {
  static values = {
    debounceTime: { type: Number, default: 300 },
  };

  initialize() {
    this.submit = debounce(this.submit.bind(this), this.debounceTimeValue);
  }

  submit() {
    this.element.requestSubmit();
  }
}
