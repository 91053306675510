import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="remote-form"
export default class extends Controller {
  static targets = ["form", "status"]
  static classes = [ "loading", "success", "error"]
  static values = {
    errorMessage: String
  }

  async submit(){
    this.statusTarget.className = '';
    this.statusTarget.classList.add(...this.loadingClasses);

    let response = await fetch(this.formTarget.action, {
      method: this.formTarget.method.toUpperCase(),
      body: new FormData(this.formTarget)
    })

    this.statusTarget.classList.remove(...this.loadingClasses);

    if (response.ok){
      this.statusTarget.classList.add(...this.successClasses);
      setTimeout( () => {this.statusTarget.className = '';}, 3000)
    } else {
      this.statusTarget.classList.add(...this.errorClasses);
      this.statusTarget.innerHTML = this.errorMessageValue;
      setTimeout( () => {
        this.statusTarget.className = '';
        this.statusTarget.innerHTML = '';
      }, 10000)
    }
  }
}
