import { Controller } from '@hotwired/stimulus';
import { mapboxSearch } from '../packs/mapbox/request'; // On conserve cet import

export default class extends Controller {
  static targets = [
    'searchInput',
    'addressNumber',
    'roadName',
    'zipCode',
    'city',
    'country',
    'longitude',
    'latitude',
    'validateSelectedValue',
  ];
  static values = { customOption: Object };

  connect() {
    this.createDropdown();
  }

  createDropdown() {
    const container = document.createElement('div');
    container.className = 'mapbox-dropdown';

    this.dropdown = document.createElement('div');
    container.appendChild(this.dropdown);
    this.searchInputTarget.parentElement.appendChild(container);
    container.hidden = true;
  }

  handleInput() {
    const input = this.searchInputTarget.value.trim();
    this.dropdown.parentElement.hidden = !input;

    // Reset the selected value if it exists
    if (this.validateSelectedValueTarget) {
      this.validateSelectedValueTarget.value = '';
    }

    if (input.length < 3) return;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      const customOption = this.hasCustomOptionValue ? this.customOptionValue : undefined;
      const results = await mapboxSearch(input, customOption);
      this.addSuggestionsToInput(results);
    }, 300);
  }

  addSuggestionsToInput(results) {
    this.dropdown.innerHTML = '';
    if (results.length === 0) {
      this.dropdown.innerHTML = '<div class="mapbox-no-suggestion">Pas de résultat à afficher.</div>';
    } else {
      results.forEach((result) => {
        const suggestionElement = document.createElement('div');
        suggestionElement.className = 'mapbox-suggestion';
        suggestionElement.innerHTML = `<span><strong>${
          result.place_name.split(',')[0]
        }</strong>${result.place_name.substring(result.place_name.indexOf(','))}</span>`;
        suggestionElement.addEventListener('click', () => {
          this.selectAddress(result);
        });
        this.dropdown.appendChild(suggestionElement);
      });
    }
    this.dropdown.parentElement.hidden = false;
  }

  selectAddress(suggestion) {
    this.searchInputTarget.value = suggestion.place_name;
    if (this.validateSelectedValueTarget) {
      this.validateSelectedValueTarget.value = suggestion.place_name;
    }

    // Utilisation des valeurs appropriées selon la structure de l'objet
    if (this.hasAddressNumberTarget) {
      this.addressNumberTarget.value = suggestion.address || ''; // Pas clair où trouver `number`, supposé `address`
    }
    if (this.hasRoadNameTarget) {
      this.roadNameTarget.value = suggestion.text_fr || ''; // Utilisez `text_fr` pour la rue
    }
    if (this.hasZipCodeTarget) {
      // Zip code might need to be parsed from `place_name` if not directly available
      const parts = suggestion.place_name.split(',');
      const possibleZip = parts[1].trim().split(' ')[0]; // Assuming format "number street, zip city, country"
      this.zipCodeTarget.value = possibleZip.match(/^\d+$/) ? possibleZip : ''; // Check if it's a number
    }
    if (this.hasCityTarget) {
      const parts = suggestion.place_name.split(',');
      this.cityTarget.value = parts[1].trim().split(' ').slice(1).join(' '); // After zip code
    }
    if (this.hasCountryTarget) {
      const parts = suggestion.place_name.split(',');
      this.countryTarget.value = parts[2].trim(); // Last part assuming format "number street, zip city, country"
    }
    if (this.hasLongitudeTarget && this.hasLatitudeTarget && suggestion.center) {
      this.longitudeTarget.value = suggestion.center[0] || ''; // Longitude
      this.latitudeTarget.value = suggestion.center[1] || ''; // Latitude
    }

    this.dropdown.parentElement.hidden = true;
  }
}
