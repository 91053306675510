import { Controller } from '@hotwired/stimulus';

// Connects to data-controller='enable-on-change
export default class extends Controller {
  static targets = ['element'];

  enable() {
    this.elementTargets.forEach((element) => {
      element.disabled = false;
    });
  }
}
