import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="class-and-text-toggle"
export default class extends Controller {
  static targets = ['element', 'button'];
  static values = {
    referenceClass: String,
    toggleClass: String,
    initialText: String,
    newText: String,
  };

  connect() {
    this.buttonTargets.forEach((button) => {
      button.innerHTML = this.initialTextValue;
    });
  }

  toggle() {
    this.elementTargets.forEach((element) => {
      element.classList.toggle(this.toggleClassValue);
    });
  }

  toggleText() {
    this.buttonTargets.forEach((button) => {
      if (button.innerHTML === this.initialTextValue) {
        button.innerHTML = this.newTextValue;
      } else {
        button.innerHTML = this.initialTextValue;
      }
    });
  }
}
