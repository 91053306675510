import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="select-toggle"
export default class extends Controller {
  static targets = ['toToggle', 'toToggleDiag', 'select', 'selectDiag'];

  toggle() {
    let select = this.selectTarget;
    this.toToggleTargets.forEach((toToggle) => {
      this.toggleTarget(select.value === toToggle.dataset.shouldToggle, toToggle);
    });
  }

  toggleDiag() {
    let select = this.selectDiagTarget;
    this.toToggleDiagTargets.forEach((toToggle) => {
      this.toggleTarget(select.value === toToggle.dataset.shouldToggle, toToggle);
    });
  }

  // private

  toggleTarget(condition, target) {
    if (condition) {
      target.classList.remove('d-none');
      target.querySelectorAll('input,textarea').forEach(function (input) {
        input.disabled = false;
      });
    } else {
      target.classList.add('d-none');
      this.toToggleDiagTargets.forEach(function (toToggle) {
        toToggle.classList.add('d-none');
      });
      target.querySelectorAll('input,textarea').forEach(function (input) {
        input.disabled = true;
      });
    }
  }
}
