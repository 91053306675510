import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  hideNotice() {
    setTimeout(function () {
      var element = document.getElementById('successful_save_message');
      if (element) {
        element.parentNode.removeChild(element);
      }
    }, 3000);
  }
}
