import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="notice-flash"
export default class extends Controller {
  static targets = ['notice'];

  remove() {
    this.noticeTarget?.remove();
  }
}
