import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="multiple-requirements"
// Set the lockedElementAttribute to true on the lockedElementTarget when we have any of the following:
// - the triggerTarget does not have the triggerAttribute (e.g checkbox is not checked)
// - the selectTarget (if present) does not have a value (e.g a select element has no option selected)
// - there is a blockingAlertTarget
// - the inputLengthTarget (if present) value length is less than its minlength attribute
export default class extends Controller {
  static targets = ['trigger', 'lockedElement', 'select', 'blockingAlert', 'inputLength'];
  static values = {
    lockedElementAttribute: 'disabled',
    triggerAttribute: 'checked',
  };

  connect() {
    this.setLockedElementFromTrigger();
  }

  toggle() {
    this.setLockedElementFromTrigger();
  }

  setLockedElementFromTrigger() {
    if (this.hasSelectTarget) {
      const isSelectValueSelected = this.selectTarget.value !== '';
      if (isSelectValueSelected) {
        this.updateTargetAttribute();
      } else {
        this.lockedElementTarget[this.lockedElementAttributeValue] = true;
      }
    } else {
      this.updateTargetAttribute();
    }

    if (this.hasBlockingAlertTarget) {
      this.lockedElementTarget[this.lockedElementAttributeValue] = true;
    }

    this.checkInputTextLength();
  }

  updateTargetAttribute() {
    this.lockedElementTarget[this.lockedElementAttributeValue] = !this.triggerTarget[this.triggerAttributeValue];
  }

  checkInputTextLength() {
    if (this.hasInputLengthTarget) {
      var min_length = this.inputLengthTarget.getAttribute('minlength');
      if (this.inputLengthTarget.value.length < min_length) {
        this.lockedElementTarget[this.lockedElementAttributeValue] = true;
      }
    }
  }
}
