import { Controller } from "@hotwired/stimulus"
import hellosign from 'hellosign-embedded';

// Connects to data-controller="hellosign"
export default class extends Controller {
  static targets = [ "button", "urlError", "alreadySignedError", "success", "spinner", "previewModal", "previewIframe" ];
  static values = {
    allowCancel: Boolean,
    openModalImmediately: Boolean,
    redirectUrl: String,
    signatureUrl: String,
    shouldPool: Boolean,
    statusUrl: String,
    tenantApplicationId: Number,
    contractUrl: String
  };

  connect() {
    this.initializeClient();
    if(this?.openModalImmediatelyValue) this.openHellosignModal();
  }

  initializeClient(){
    this.hellosignClient = new hellosign();
    this.hellosignClient.on('sign', () => this.handleSignature())
    this.hellosignClient.on('cancel', () => this.handleCancellation())
    this.jsonResponse = {};
  }

  handleSignature(){
    if(!this.shouldPoolValue){
      this.spinnerTarget.style.display = 'none';
      this.successTarget.style.display = 'block';
      return
    }

    this.waitForHookReception()
        .then(() => this.reloadPage())
        .catch(() => this.handleHookError())
  }

  handleCancellation(){
    this.hellosignClient.close();
    window.tenantApplicationId = this.tenantApplicationIdValue;
    window.openCancelContractSignature();
  }

  async waitForHookReception(){
    let tenantSignerUpdated = false;
    let shouldPool = true;
    let i = 0;
    while(shouldPool){
      await new Promise(res => setTimeout(res, 1000 * (1 + i/2)))
      tenantSignerUpdated = await this.checkHookStatus();
      i++;
      shouldPool = !tenantSignerUpdated && i < 10 // max number of retries
    }
    return new Promise((resolve, reject) => tenantSignerUpdated ? resolve() : reject('hook not received'))
  }

  checkHookStatus(){
    return fetch(this.statusUrlValue)
      .then(response => response.json())
      .then(json => {
        return new Promise((resolve) => resolve(json.success))
      })
  }

  reloadPage(){
    window.location.href = this.redirectUrlValue;
  }

  handleHookError(){
    this.spinnerTarget.style.display = 'none';
  }

  openHellosignModal(){
    if(this.hasButtonTarget) this.buttonTarget.disabled = true;
    this.spinnerTarget.style.display = 'inline-block';
    fetch(this.signatureUrlValue)
      .then(response => response.json())
      .then(json => {
        this.jsonResponse = json;
        if(this.hasPreviewModalTarget) $(this.previewModalTarget).modal('hide');
        this.hellosignClient.open(json.signed_url, this.formatOptions(json))
      })
      .catch(()=> this.handleUrlError())
  }

  formatOptions (response) {
    return {
      clientId: response.client_id,
      skipDomainVerification: response.skip_domain_verification,
      allowCancel: this.allowCancelValue,
    }
  }

  handleUrlError(){
    if(this.hasPreviewModalTarget) $(this.previewModalTarget).modal('hide');
    this.spinnerTarget.style.display = 'none';
    const target = this.jsonResponse.already_signed ? this.alreadySignedErrorTarget : this.urlErrorTarget;
    target.style.display = 'block';
  }

  abort(){
    this.spinnerTarget.style.display = 'none';
    this.buttonTarget.disabled = false;
  }

  switchPreviewModalContent() {
    this.previewIframeTarget.src = this.contractUrlValue;
  }
}
