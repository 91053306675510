import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="save_form"
export default class extends Controller {
  static targets = [ 'form', 'submitRow', 'feedbackRow', 'spinner' ]

  connect() {
    this.formTargets.forEach(el => {
      el.addEventListener('input', () => this.makeRowSticky());
    });

    if(this.hasFeedbackRowTarget){
      setTimeout(() => {
        this.feedbackRowTarget.classList.add('d-none');
      }, 4500);
    }
  }

  disconnect() {
    this.formTargets.forEach(el => {
      el.removeEventListener('change', () => this.makeRowSticky())
    });
  }

  makeRowSticky() {
    this.submitRowTarget.classList.add('sticky-bottom');
    this.submitRowTarget.classList.remove('d-none');
  }

  submit() {
    if (this.formTarget.checkValidity()) {
      this.spinnerTarget.classList.remove('d-none');
    }
  }

}
