import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="checkup"
export default class extends Controller {
  static targets = ['form', 'nodeId', 'level'];

  refreshLevel(event) {
    this.levelTarget.value = event.target.value;
    this.formTarget.requestSubmit();
  }

  refreshNodeId(event) {
    if (event.target.value !== '') {
      this.nodeIdTarget.value = event.target.value;
      this.formTarget.requestSubmit();
    }
  }
}
