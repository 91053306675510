import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="internal--flats--tenant-contract"
export default class extends Controller {
  static targets = [
    'guarantee',
    'rent',
    'charges',
    'numberOfMonthsToDeposit',
    'area',
    'rentRefMaj',
    'missingRentRefMaj',
    'rentExcessAmount',
    'rentExcessJustificationText',
    'dpeFOrGRentExcessJustification',
  ];

  static values = {
    originalRent: Number,
    ignoreDpe: Boolean,
  };

    refreshNumberOfMonthsToDeposit() {
        const rent_no_charges = parseFloat(this.rentTarget.value) - parseFloat(this.chargesTarget.value);
        const guarantee = parseFloat(this.guaranteeTarget.value);
        this.numberOfMonthsToDepositTarget.value = Math.round(10 * guarantee / rent_no_charges) / 10;
    }

    refreshDeposit() {
        const rent_no_charges = parseFloat(this.rentTarget.value) - parseFloat(this.chargesTarget.value);
        if (document.getElementById('flat_furnished').checked) {
            this.guaranteeTarget.value = Math.round(rent_no_charges * 2);
        } else {
            this.guaranteeTarget.value = Math.round(rent_no_charges);
        }
        this.refreshNumberOfMonthsToDeposit();
        this.refreshDpeFOrGRentExcessJustification();
    }

    refreshRentExcessJustification() {
        const rent_ref_maj = parseFloat(this.rentRefMajTarget.value);
        const rent_no_charges = parseFloat(this.rentRefMajTarget.dataset.rentNc);
        const area = parseFloat(this.rentRefMajTarget.dataset.area);

        if (isNaN(rent_ref_maj)) {
            this.missingRentRefMajTarget.classList.remove('d-none');
            document.getElementById('flat_tenant_contract_rent_ref_maj')
                    .setAttribute('required', '');
        } else {
            this.missingRentRefMajTarget.classList.add('d-none');
        }

        if (isNaN(area) || isNaN(rent_ref_maj) || isNaN(rent_no_charges)) { return; }

        const flat_rent_ref_maj = area * rent_ref_maj;
        const rent_complement =  (rent_no_charges - flat_rent_ref_maj).toFixed(2);

        if (rent_complement > 0 && document.getElementById('flat_tenant_contract_is_rent_legaly_maxed').checked) {
            this.rentExcessAmountTarget.value = rent_complement + ' €';
            this.rentExcessJustificationTextTarget.classList.remove('d-none');
            const el = document.getElementById('flat_tenant_contract_rent_excess_justification');
            el.setAttribute('required', '');
            el.disabled = false;
        } else {
            this.rentExcessAmountTarget.value = 'Pas de complément';
            this.rentExcessJustificationTextTarget.classList.add('d-none');
            document.getElementById('flat_tenant_contract_rent_excess_justification')
                    .removeAttribute('required');
        }
    }

    refreshDpeFOrGRentExcessJustification() {
      if (this.ignoreDpeValue) return;

      const rent = parseFloat(this.rentTarget.value);
  
      if (isNaN(rent)) return;
  
      if (rent > this.originalRentValue) {
        this.dpeFOrGRentExcessJustificationTarget.classList.remove('d-none');
        document.getElementById('flat_dpe_f_or_g_rent_excess_justification').setAttribute('required', '');
      } else {
        this.dpeFOrGRentExcessJustificationTarget.classList.add('d-none');
        document.getElementById('flat_dpe_f_or_g_rent_excess_justification').removeAttribute('required');
      }
    }
}
