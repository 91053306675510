import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="admin--support-request--form-elements-fields"
export default class extends Controller {
  static targets = ['labelRow', 'nextNodeId', 'options', 'optionsField', 'optionsToParseField', 'elementType'];

  connect() {
    if (this.elementTypeTarget.value !== 'select_field') {
      this.optionsFieldTarget.value = '';
    } else if (this.optionsFieldTarget.value) {
      const parsedOptions = JSON.parse(this.optionsFieldTarget.value);
      if (parsedOptions.select_options) {
        this.optionsToParseFieldTarget.value = parsedOptions.select_options.map((option) => option[0]).join('; ') + ';';
      }
    }
  }

  toggleLabel(event) {
    if (event.target.value === 'radio_button') {
      this.labelRowTarget.classList.remove('d-none');
      this.optionsTarget.classList.add('d-none');
    } else if (event.target.value === 'select_field') {
      this.labelRowTarget.classList.add('d-none');
      this.optionsTarget.classList.remove('d-none');
    } else {
      this.labelRowTarget.classList.add('d-none');
      this.optionsTarget.classList.add('d-none');
    }
  }

  setNextNodeId(nextNodeId) {
    this.nextNodeIdTarget.value = nextNodeId;
  }

  parseOptions(event) {
    const optionsString = event.target.value;
    const optionsArray = optionsString
      .split(';')
      .map((option) => option.trim())
      .filter((option) => option.length > 0);
    const parsedOptions = { select_options: optionsArray.map((option) => [option, option]) };
    this.optionsFieldTarget.value = JSON.stringify(parsedOptions);
  }
}
