import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="beacon-requests"
export default class extends Controller {
  static values = {
    url: String
  }

  initialize(){
    this.payload = {}
  }

  connect() {
    window.addEventListener('visibilitychange', () => this.sendBeacon());
  }

  disconnect() {
    window.removeEventListener('visibilitychange', () => this.sendBeacon());
  }

  updatePayload({ detail: object }){
    this.payload = object;
  }

  sendBeacon(){
    if (Object.keys(this.payload).length === 0) return;

    navigator.sendBeacon(this.urlValue, this.formData());
  }

  formData(){
    const formData = new FormData();
    for (const [key, value] of Object.entries( this.payload )) {
      if (Array.isArray(value) && value.length){
        value.forEach((elem) => { formData.append(key, elem) });
      } else {
        formData.append(key, value);
      }
    }
    // add CSRF token
    var param = document.querySelector("meta[name=csrf-param]").getAttribute("content");
    var token = document.querySelector("meta[name=csrf-token]").getAttribute("content");
    formData.append(param, token);
    return formData;
  }
}
