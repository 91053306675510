import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="internal--flats--property-upload"
export default class extends Controller {
  static targets = [
    'areaField',
    'pieceStatusSelect',
    'areaFieldShow',
    'selectDiagType',
    'selectUploadType',
    'amianteField',
    'expirationDate',
    'realisationDateField',
    'realisationDate',
    'checkBoxNoAmiante',
    'checkBoxWithAmiante',
  ];

  connect() {
    this.surfaceNeeded();
    if (this.hasAmianteFieldTarget) {
      this.amianteDisplay();
    }
    if (this.hasRealisationDateFieldTarget && this.hasExpirationDateTarget) {
      this.realisationDateDisplay();
    }
  }

  surfaceNeeded() {
    const mesurage_loi_boutin = 4; // upload_type vaut 4 dans le cas du mesurage loi boutin
    const diagnostic = 10; // upload_type vaut 10 dans le cas d'un diagnostique
    const attestation_surface = 3; // diag_type vaut 3 dans le cas d'une attestation de surface habitable

    let selectUploadType;
    let selectDiagType;

    if (this.hasSelectUploadTypeTarget) {
      selectUploadType = this.selectUploadTypeTarget;
      if (this.hasSelectDiagTypeTarget) {
        selectDiagType = this.selectDiagTypeTarget;
      } else {
        selectDiagType = { value: null };
      }
    } else {
      selectUploadType = { value: diagnostic };
      selectDiagType = this.selectDiagTypeTarget;
    }
    const pieceStatus = this.pieceStatusSelectTarget;
    const areaFieldShow = this.areaFieldShowTarget;
    const areaField = this.areaFieldTarget;

    if (selectUploadType.value === mesurage_loi_boutin) {
      areaFieldShow.classList.remove('d-none');
      areaField.removeAttribute('disabled');
      this.areaFieldTargets.forEach((e) => {
        e.required = pieceStatus.value === 'validated';
      });
    } else if (selectUploadType.value === diagnostic && selectDiagType.value === attestation_surface) {
      areaFieldShow.classList.remove('d-none');
      areaField.removeAttribute('disabled');
    } else {
      areaField.setAttribute('disabled', '');
    }
    if (
      selectUploadType.value == mesurage_loi_boutin ||
      (selectDiagType.value == attestation_surface && selectUploadType.value == diagnostic)
    ) {
      this.areaFieldTargets.forEach((e) => {
        e.required = pieceStatus.value === 'validated';
        if (e.required) {
          areaFieldShow.classList.remove('d-none');
          areaField.removeAttribute('disabled');
        } else {
          areaFieldShow.classList.add('d-none');
          areaField.setAttribute('disabled', '');
        }
      });
    } else {
      areaField.setAttribute('disabled', '');
    }
    // plus simple avec == que de convertir le string en int
  }

  amianteDisplay() {
    const amianteField = this.amianteFieldTarget;
    const selectUploadType = this.hasSelectUploadTypeTarget ? this.selectUploadTypeTarget : { value: '10' };
    const selectDiagType = this.hasSelectDiagTypeTarget ? this.selectDiagTypeTarget : { value: '4' };

    if (selectUploadType.value === '10' && selectDiagType.value === '4') {
      amianteField.classList.remove('d-none');
    } else {
      amianteField.classList.add('d-none');
    }
  }
  realisationDateDisplay() {
    const realisationDateField = this.realisationDateFieldTarget;
    const selectUploadType = this.hasSelectUploadTypeTarget ? this.selectUploadTypeTarget : { value: '10' };
    const expirationDate = this.expirationDateTarget;

    if (selectUploadType.value === '10') {
      realisationDateField.classList.remove('d-none');
      expirationDate.setAttribute('readonly', 'true');
    } else {
      realisationDateField.classList.add('d-none');
      expirationDate.removeAttribute('readonly');
    }
  }

  diagGetExpirationDate() {
    const realisationDate = this.realisationDateTarget;
    const expirationDate = this.expirationDateTarget;
    const checkBoxNoAmiante = this.checkBoxNoAmianteTarget;
    const selectDiagType = this.hasSelectDiagTypeTarget ? this.selectDiagTypeTarget : { value: null };
    const expirationDateValue = new Date(realisationDate.value);

    switch (selectDiagType.value) {
      //DPE - 1 year
      case '1':
        expirationDateValue.setFullYear(expirationDateValue.getFullYear() + 10);
        break;
      //ERP - 6 months
      case '2':
        expirationDateValue.setMonth(expirationDateValue.getMonth() + 6);
        break;
      //SHR - unlimited
      case '3':
        expirationDateValue.setFullYear(expirationDateValue.getFullYear() + 100);
        break;
      //DA - 100 years no amiante, 3 years with amiante
      case '4':
        if (checkBoxNoAmiante.checked) {
          expirationDateValue.setFullYear(expirationDateValue.getFullYear() + 100);
        } else {
          expirationDateValue.setFullYear(expirationDateValue.getFullYear() + 3);
        }
        break;
      // CREP - 6 years
      case '5':
        expirationDateValue.setFullYear(expirationDateValue.getFullYear() + 6);
        break;
      //Electricity - 6 years
      case '6':
        expirationDateValue.setFullYear(expirationDateValue.getFullYear() + 6);
        break;
      //Gaz - 6 years
      case '7':
        expirationDateValue.setFullYear(expirationDateValue.getFullYear() + 6);
        break;
      default:
        break;
    }
    if (!isNaN(expirationDateValue)) {
      expirationDate.value = expirationDateValue.toISOString().split('T')[0];
    } else {
      expirationDate.value = '';
    }
  }

  toggleCheckBox(event) {
    const currentCheckbox = event.currentTarget;
    const checkBoxNoAmiante = this.checkBoxNoAmianteTarget;
    const checkBoxWithAmiante = this.checkBoxWithAmianteTarget;

    if (currentCheckbox.id == 'checkBoxWithAmiante') {
      checkBoxNoAmiante.checked = !currentCheckbox.checked;
    } else {
      checkBoxWithAmiante.checked = !currentCheckbox.checked;
    }
  }

  handleTextClick() {
    this.checkBoxNoAmianteTarget.checked = !this.checkBoxNoAmianteTarget.checked;
    this.checkBoxWithAmianteTarget.checked = !this.checkBoxWithAmianteTarget.checked;
    this.diagGetExpirationDate();
  }
}
