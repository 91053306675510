import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['link', 'tab'];

  initialize() {
    const location = window.location.href;
    this.linkTargets.forEach((link) => {
      if (location.includes(link.href)) {
        link.classList.remove('link-gray');
      } else {
        link.classList.add('link-gray');
      }
    });
    this.tabTargets[0]?.classList.add('active');
  }

  toggleActive(event) {
    if (this.linkTargets.includes(event.currentTarget)) {
      this.linkTargets.forEach((link) => {
        link.classList.add('link-gray');
      });
      event.currentTarget.classList.remove('link-gray');
    }
    if (this.tabTargets.includes(event.currentTarget)) {
      this.tabTargets.forEach((tab) => {
        tab.classList.remove('active');
      });
      event.currentTarget.classList.add('active');
    }
  }
}
