import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="landlord--payments-recap"
export default class extends Controller {
  static targets = [
    'form',
    'submitButton',
    'loader',
    'loadingTimeMessage',
    'link',
    'errorMessage',
    'paymentChartForm',
    'dateInputFrom',
    'dateInputTo',
    'dateInputFromForExtract',
    'dateInputToForExtract',
    'paymentChart',
    'dataViz',
  ];

  connect() {
    this.drawLine();
  }

  onGeneratePaymentRecap() {
    this.submitButtonTarget.disabled = true;
    this.submitButtonTarget.value = 'Génération en cours';
    this.loaderTarget.classList.remove('d-none');
    this.loadingTimeMessageTarget.classList.remove('d-none');
    this.linkTargets.forEach((link) => link.classList.add('d-none'));
  }

  beforeStreamRender(event) {
    if (event.target.target !== 'payment-recap-link' && event.target.target !== 'error-message') return;

    this.loaderTarget.classList.add('d-none');
    this.loadingTimeMessageTarget.classList.add('d-none');
    this.submitButtonTarget.value = 'Générer un nouvel extrait de compte';
  }

  onDateChange() {
    this.errorMessageTarget.textContent = '';
    this.paymentChartFormTarget.requestSubmit();
  }

  onTurboFrameLoad() {
    this.drawLine();
    this.dateInputFromForExtractTarget.value = this.dateInputFromTarget.value;
    this.dateInputToForExtractTarget.value = this.dateInputToTarget.value;
  }

  drawLine() {
    if (!this.hasPaymentChartTarget || !this.hasDataVizTarget) return;

    const labels = JSON.parse(this.dataVizTarget.dataset.labels);
    const numbers = JSON.parse(this.dataVizTarget.dataset.numbers).map(Number);

    const ctx = this.paymentChartTarget.getContext('2d');
    new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          {
            label: '',
            data: numbers,
            borderWidth: 2,
            borderColor: '#87B491',
            fill: 0,
            lineTension: 0,
            pointBackgroundColor: 'white',
            pointRadius: 4,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: false },
        scales: {
          yAxes: [
            {
              gridLines: {
                color: '#BCBCBC',
              },
              ticks: {
                beginAtZero: true,
                callback: function (value) {
                  return value + '€';
                },
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                callback: function (value, index) {
                  return [value, Math.round(numbers[index]) + '€'];
                },
              },
            },
          ],
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              return data['datasets'][0]['data'][tooltipItem['index']] + '€';
            },
          },
          bodyFontSize: 16,
          displayColors: false,
        },
      },
    });
  }
}
