import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="internal--actions-modal"
export default class extends Controller {
  static targets = ['modal', 'searchInput', 'contentHelper', 'contentSearch', 'form'];

  connect() {
    this.timeout = 400;
    let modal = new bootstrap.Modal(this.modalTarget, { keyboard: false });
    document.addEventListener('keydown', function (event) {
      if (event.metaKey && event.key === 'k') {
        event.preventDefault();
        modal.toggle();
        this.searchInputTarget.focus();
      } else if (event.key === 'Escape') {
        modal.hide();
      }
    });

    document.querySelectorAll('.trigger-actions-modal').forEach((trigger) => {
      trigger.addEventListener('click', () => {
        modal.toggle();
        this.searchInputTarget.focus();
      });
    });
  }

  action() {
    const focusableElements = Array.from(document.querySelectorAll('.action-link'));
    focusableElements[0].click();
  }

  search(event) {
    let firstChar = event.target.value.charAt(0);
    if (firstChar === '?') {
      this.contentHelperTarget.classList.remove('d-none');
      this.contentSearchTarget.classList.add('d-none');
    } else {
      this.contentHelperTarget.classList.add('d-none');
      this.contentSearchTarget.classList.remove('d-none');

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.formTarget.requestSubmit();
      }, 350);
    }
  }

  replaceSearchInput(event) {
    this.searchInputTarget.value = event.params.text;
    this.searchInputTarget.focus();
  }
}
