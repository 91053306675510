import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="internal--flats--check-confirmed-step1"
export default class extends Controller {
  static targets = ['form', 'errorMessage'];

  async submit() {
    let unvalidForms = false;
    this.formTargets.forEach(function (form) {
      unvalidForms ||= !form.checkValidity();
    });

    if (document.getElementsByTagName('trix-editor')[0].value == '') {
      unvalidForms ||= true;
    }

    if (unvalidForms) {
      this.errorMessageTarget.classList.remove('d-none');
      this.errorMessageTarget.scrollIntoView();
      return;
    }

    async function completeForm(forms) {
      for (const form of forms) {
        // create a promise that resolves when the form is submitted
        // when the form is submitted, the submit event resolv the promise
        const formSubmitted = new Promise((resolve) => {
          form.addEventListener('turbo:submit-end', resolve, { once: true });
        });
        // submit the form
        form.requestSubmit();
        // wait for the form submission to complete
        await formSubmitted;
      }
    }
    await completeForm(this.formTargets);

    window.dispatchEvent(new CustomEvent("check-confirmed-step-1-validated"));
  }
}
