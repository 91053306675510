import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="recompute-last-payment"
export default class extends Controller {
  static targets = ['checked', 'unchecked', 'showButton', 'hideButton', 'totalAmount', 'chargedAmount', 'refundAmount'];

  connect() {
    this.computeTotalAmount();
  }

  show() {
    this.uncheckedTargets.forEach((target) => target.classList.remove('d-none'));
    this.showButtonTarget.classList.add('d-none');
    this.hideButtonTarget.classList.remove('d-none');
  }

  hide() {
    this.uncheckedTargets.forEach((target) => target.classList.add('d-none'));
    this.hideButtonTarget.classList.add('d-none');
    this.showButtonTarget.classList.remove('d-none');
  }

  computeTotalAmount() {
    let amount = 0;
    this.chargedAmountTargets.forEach((target) => {
      if (!isNaN(parseFloat(target.innerHTML))) {
        amount += parseFloat(target.innerHTML);
      }
    });
    this.refundAmountTargets.forEach((target) => {
      if (!isNaN(parseFloat(target.innerHTML))) {
        amount -= parseFloat(target.innerHTML);
      }
    });
    this.totalAmountTarget.innerHTML = `${Math.round(amount * 100) / 100} €`;
  }
}
