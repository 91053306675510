import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = ['dropdown', 'button', 'menu'];

  connect() {
    // prevent bootstrap dropdown to auto-close when clicking inside
    this.dropdownTarget.addEventListener('click', e => e.stopPropagation());
  }

  updateDropdownState() {
    let checkboxChecked = !!this.menuTarget.querySelector('input:checked');

    if (checkboxChecked) {
      this.buttonTarget.classList.add('active');
    } else {
      this.buttonTarget.classList.remove('active');
    }
  }
}
