import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'form',
    'select',
    'clearButton',
    'formText',
    'flatIds',
    'textInput',
    'clearFormTextButton',
    'agentId',
    'agentIdFormText',
    'selectAgentsDropdown',
    'checkboxLastTenantApplication',
    'selectStatusDropdown',
    'status',
    'statusField',
    'statusFieldText',
    'clearStatusButton',
    'formStatus',
    'agentIdStatus',
    'currentStatus',
    'onlyImpossibleStatus',
    'onlyImpossibleStatusCount',
    'onlyImpossibleStatusCountMirror',
    'selectDomain',
    'clearDomainButton',
    'selectDomainsDropdown',
    'domainId',
    'domainIdStatus',
  ];
  static classes = ['filled', 'empty', 'hidden', 'visible'];
  searchTerm = '';
  searchTextId = 0;

  connect() {
    var urlParams = new URLSearchParams(window.location.search);
    var agent_id = urlParams.get("agent_id");
    var flat_ids = urlParams.get("flat_ids");
    var status = urlParams.get("status");
    var domain_id = urlParams.get('domain_id');
    var select_agent_text = document.querySelectorAll("[data-agent-id='" + agent_id + "']")[0];
    var select_domain_text = document.querySelectorAll("[data-domain-id='" + domain_id + "']")[0];

    if (this.hasTextInputTarget) {
      this.textInputTarget.focus();
    }
    if (this.hasClearStatusButtonTarget) {
      this.clearStatusButtonTarget.hidden = true;
    }
    if (this.hasClearDomainButtonTarget) {
      this.clearDomainButtonTarget.hidden = true;
    }
    if (this.hasAgentIdStatusTarget) {
      this.agentIdStatusTarget.value = this.agentIdTarget.value
    }
    if (this.hasDomainIdStatusTarget) {
      this.domainIdStatusTarget.value = this.domainIdTarget.value;
    }
    if (this.hasSelectStatusDropdownTarget) {
      this.addHiddenClassToDropdown(this.selectStatusDropdownTarget);
    }
    if (agent_id != null && select_agent_text != null) {
      this.selectTarget.textContent = select_agent_text.textContent ;
      this.setActiveAgent(agent_id);
    }
    if (domain_id != null && select_domain_text != null) {
      this.selectDomainTarget.textContent = select_domain_text.textContent;
      this.setActiveDomain(domain_id);
    }
    if (flat_ids != null) {
      flat_ids = flat_ids.split(',').join(', #');
      this.textInputTarget.value = "Logements : #" + flat_ids;
      this.textInputTarget.disabled = true;
      this.clearFormTextButtonTarget.style.display = 'block';
      if (agent_id == null) {
        this.agentIdTarget.value = null;
        this.resetPortfolioSelectText();
        this.setActiveAgent(null);
        this.addEmptyClassToSelect(this.selectTarget, this.clearButtonTarget);
      }
    }
    if (status != null) {
      this.statusTarget.classList.remove(...this.emptyClasses);
      this.statusTarget.classList.add(...this.filledClasses);
      this.clearStatusButtonTarget.hidden = false;
    }
    if (this.hasFormStatusTarget) {
      this.agentIdStatusTarget.value = 0;
    }
    if(this.hasOnlyImpossibleStatusTarget && this.hasOnlyImpossibleStatusCountTarget){
      document.addEventListener('turbo:frame-load',
       () => {
        this.updateImpossibleStatusCount(
          this.onlyImpossibleStatusCountTarget,
          this.onlyImpossibleStatusCountMirrorTarget)
       }
      );
    }
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }

  keyboardEvent (event) {
    let select_flat_search = document.getElementById('select_flat_search');
    if (select_flat_search != null && !select_flat_search.hidden) {
      if (event.key == 'ArrowUp') {
        event.preventDefault();
        this.iterDropDownSearchText(-1);
      } else if (event.key == 'ArrowDown') {
        event.preventDefault();
        this.iterDropDownSearchText(1);
      } else if (event.key == 'Enter') {
        document.getElementById('select_flat_search').children[this.searchTextId].click()
      }
    }
  }

  clickOnSearchBar() {
    if (this.textInputTarget.disabled) {
      this.textInputTarget.disabled = false;
      this.textInputTarget.value = this.searchTerm;
      this.textInputTarget.focus();
      this.clearImpossibleCountBtn();
      this.formRequestSubmit(this.formTextTarget);
    }
  }

  makeRequest() {
    clearTimeout(this.timeout);
    this.formRequestSubmit(this.formStatusTarget);
  }

  filterByAgent(event) {
    this.clearSelect();
    this.agentIdTarget.value = event.params.agent.id;
    this.selectTarget.textContent = event.params.agent.name;
    this.setSelectClass();
    this.setActiveAgent(event.params.agent.id);
    this.clearImpossibleCountBtn();
    this.formRequestSubmit(this.formTarget);
  }

  filterByDomain(event) {
    this.domainIdTarget.value = event.params.domain.id;
    this.selectDomainTarget.textContent = event.params.domain.name;
    this.setDomainClass();
    this.setActiveDomain(event.params.domain.id);
    this.clearImpossibleCountBtn();
    this.formRequestSubmit(this.formTarget);
    this.hideDomainDropdown();
  }

  filterByIds(event) {
    this.setFlatIds(event.params.payload.flat_ids);
    this.formRequestSubmit(this.formTarget);
    this.searchTerm = this.textInputTarget.value;
    this.textInputTarget.value = event.params.payload.type + event.target.outerText;
    this.textInputTarget.disabled = true;
    this.clearImpossibleCountBtn();
    this.clearFormTextButtonTarget.style.display = 'block';
  }

  filterByStatus(event) {
    this.statusFieldTarget.value = event.params.agent.status;
    this.currentStatusTarget.value = event.params.agent.status;
    this.statusTarget.textContent = event.params.agent.fr_status;
    this.setActiveStatus(event.params.agent.status);
    this.statusTarget.classList.remove(...this.emptyClasses);
    this.statusTarget.classList.add(...this.filledClasses);
    this.clearStatusButtonTarget.hidden = false;
    this.clearImpossibleCountBtn();
    this.formRequestSubmit(this.formTarget);
  }

  filterByImpossibleStatus() {
    if (this.onlyImpossibleStatusTarget.value === 'true') {
      this.clearImpossibleCountBtn();
      this.onlyImpossibleStatusTarget.value = false;
    } else {
      this.onlyImpossibleStatusCountMirrorTarget.querySelector('i').style.display = 'inline-block';
      this.onlyImpossibleStatusTarget.value = true;
    }
    var tooltip = bootstrap.Tooltip.getInstance(this.onlyImpossibleStatusCountMirrorTarget);
    tooltip.hide();
    this.formRequestSubmit(this.formTarget);
  }

  clearSelect() {
    this.agentIdTarget.value = null;
    this.resetPortfolioSelectText();
    this.setActiveAgent(null);
    this.addEmptyClassToSelect(this.selectTarget, this.clearButtonTarget);
    this.clearButtonTarget.hidden = true;
    this.clearImpossibleCountBtn();
    this.formRequestSubmit(this.formTarget);
  }

  clearStatus() {
    this.statusFieldTarget.value = null;
    this.statusTarget.textContent = "Statut";
    this.setActiveStatus(null);
    this.addEmptyClassToSelect(this.statusTarget, this.clearStatusButtonTarget);
    this.clearImpossibleCountBtn();
    this.formRequestSubmit(this.formTarget);
  }

  clearFormText() {
    this.clearFormTextButtonTarget.style.display = 'none';
    this.textInputTarget.value = '';
    this.textInputTarget.disabled = false;
    this.setFlatIds(null);
    this.clearImpossibleCountBtn();
    this.formRequestSubmit(this.formTarget);
  }

  clearDomainSelect() {
    this.domainIdTarget.value = null;
    this.selectDomainTarget.textContent = 'Tous les domaines';
    this.setActiveDomain(null);
    this.addEmptyClassToSelect(this.selectDomainTarget, this.clearDomainButtonTarget);
    this.clearDomainButtonTarget.hidden = true;
    this.clearImpossibleCountBtn();
    this.formRequestSubmit(this.formTarget);
  }

  clearImpossibleStatus(){
    this.clearImpossibleCountBtn();
  }

  displayAllDomains() {
    this.clearDomainSelect();
    this.hideDomainDropdown();
  }

  searchText() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.agentIdFormTextTarget.value = this.agentIdTarget.value
      this.statusFieldTextTarget.value = this.statusFieldTarget.value
      this.clearImpossibleCountBtn();
      this.formRequestSubmit(this.formTextTarget);
      this.searchTextId = 0;
    }, 300);
  }

  toggleDropdown(event) {
    switch (event.target.id) {
      case 'select-agent-filter':
        if (this.selectAgentsDropdownTarget.classList.contains("d-none")) {
          this.addVisibleClassToDropdown(this.selectAgentsDropdownTarget);
        } else {
          this.addHiddenClassToDropdown(this.selectAgentsDropdownTarget);
        }
        this.hideSearchDropdown();
        break;
      case 'select-status-filter':
        if (this.selectStatusDropdownTarget.classList.contains("d-none")) {
          this.addVisibleClassToDropdown(this.selectStatusDropdownTarget);
        } else {
          this.addHiddenClassToDropdown(this.selectStatusDropdownTarget);
        }
        if (this.agentIdStatusTarget.value != this.agentIdTarget.value) {
          this.agentIdStatusTarget.value = this.agentIdTarget.value;
          this.makeRequest();
        } else if (this.domainIdStatusTarget.value != this.domainIdTarget.value) {
          this.domainIdStatusTarget.value = this.domainIdTarget.value;
          this.makeRequest();
        }
        this.hideSearchDropdown();
        break;
      case 'select-domain-filter':
        if (this.selectDomainsDropdownTarget.classList.contains('d-none')) {
          this.addVisibleClassToDropdown(this.selectDomainsDropdownTarget);
        } else {
          this.addHiddenClassToDropdown(this.selectDomainsDropdownTarget);
        }
        if (this.agentIdStatusTarget.value != this.agentIdTarget.value) {
          this.agentIdStatusTarget.value = this.agentIdTarget.value;
          this.makeRequest();
        }
        this.hideSearchDropdown();
        break;
      default:
        this.hideSearchDropdown();
        if (this.hasSelectAgentsDropdownTarget) {
          this.addHiddenClassToDropdown(this.selectAgentsDropdownTarget);
        }
        if (this.hasSelectStatusDropdownTarget) {
          this.addHiddenClassToDropdown(this.selectStatusDropdownTarget);
        }
        break;
    }
  }

  // private

  iterDropDownSearchText(iteration) {
    let list = document.getElementById('select_flat_search').children;
    list[this.searchTextId].classList.remove('selected-item');
    list[this.searchTextId].children[0].classList.remove('selected-item');
    this.searchTextId = (this.searchTextId + iteration) % list.length;
    this.searchTextId = this.searchTextId === -1 ? list.length - 1 : this.searchTextId;
    let classes = list[this.searchTextId].className
    if( !classes.includes('selectable-item') && !classes.includes('last-select-item')) {
      this.iterDropDownSearchText(iteration);
    } else if (classes.includes('last-select-item')) {
      list[this.searchTextId].children[0].classList.add('selected-item');
    } else {
      list[this.searchTextId].classList.add('selected-item');
    }
  }

  formRequestSubmit(target) {
    this.writeParamsInUrl();

    if (typeof target.requestSubmit === "function") {
      target.requestSubmit();
    } else {
      var submitter = document.createElement("input");
      submitter.type = "submit";
      submitter.hidden = true;
      target.appendChild(submitter);
      submitter.click();
      target.removeChild(submitter);
    }
    if (this.hasTextInputTarget) {
      this.textInputTarget.focus();
    }
  }

  writeParamsInUrl() {
    let agent_id = '',
      flat_ids = '',
      status = '',
      domain_id = '';
    if (this.hasAgentIdTarget) {
      agent_id = this.agentIdTarget.value;
    }
    if (this.hasDomainIdTarget) {
      domain_id = this.domainIdTarget.value;
    }
    if (this.hasIdsTarget) {
      flat_ids = this.flatIdsTarget.value;
    }
    if (this.hasStatusFieldTarget) {
      status = this.statusFieldTarget.value;
    }
    if (this.hasFlatIdsTarget) {
      flat_ids = this.flatIdsTarget.value;
    }
    const params_string = [];
    if (agent_id !== '') {
      params_string.push("agent_id=" + agent_id);
    }
    if (domain_id !== '') {
      params_string.push('domain_id=' + domain_id);
    }
    if (flat_ids !== "") {
      params_string.push("flat_ids=" + flat_ids);
    }
    if (status !== "") {
      params_string.push("status=" + status);
    }
    const params_url = `?${params_string.join('&')}`;
    window.history.replaceState(null, null, params_url);
  }

  addFilledClassToSelect(someSelectTarget, someButtonTarget) {
    someSelectTarget.classList.remove(...this.emptyClasses);
    someSelectTarget.classList.add(...this.filledClasses);
    someButtonTarget.hidden = false;
  }

  addEmptyClassToSelect(select_target, clearButtonTarget) {
    select_target.classList.remove(...this.filledClasses);
    select_target.classList.add(...this.emptyClasses);
    clearButtonTarget.hidden = true;
  }

  setSelectClass() {
    if (this.agentIdTarget.value == "") {
      this.addEmptyClassToSelect(this.selectTarget, this.clearButtonTarget);
    } else {
      this.addFilledClassToSelect(this.selectTarget, this.clearButtonTarget);
    }
  }

  setDomainClass() {
    if (this.domainIdTarget.value === '') {
      this.addEmptyClassToSelect(this.selectDomainTarget, this.clearDomainButtonTarget);
    } else {
      this.addFilledClassToSelect(this.selectDomainTarget, this.clearDomainButtonTarget);
    }
  }

  addHiddenClassToDropdown(target_dropdown) {
    target_dropdown.classList.remove(...this.visibleClasses);
    target_dropdown.classList.add(...this.hiddenClasses);
  }

  addVisibleClassToDropdown(target_dropdown) {
    target_dropdown.classList.remove(...this.hiddenClasses);
    target_dropdown.classList.add(...this.visibleClasses);
  }

  setFlatIds(flatIds) {
    this.flatIdsTarget.value = flatIds;
  }

  setActiveAgent(active_agent_id) {
    for (var selectOption of document.getElementsByClassName("select-item-for-agent")) {
      selectOption.classList.remove("active-item");
      selectOption.classList.add("selectable-item");
      if (selectOption.dataset.agentId == active_agent_id) {
        selectOption.classList.add("active-item");
        selectOption.classList.remove("selectable-item");
      }
    }
  }

  setActiveDomain(active_domain_id) {
    for (var selectOption of document.getElementsByClassName('select-item-for-domain')) {
      selectOption.classList.remove('active-item');
      selectOption.classList.add("selectable-item");
      if (selectOption.dataset.domainId == active_domain_id) {
        selectOption.classList.add('active-item');
        selectOption.classList.remove('selectable-item');
      }
    }
  }

  setActiveStatus(active_status) {
    for (var selectOption of document.getElementsByClassName("select-item-for-status")) {
      selectOption.classList.remove("active-item");
      selectOption.classList.add("selectable-item");
      if (selectOption.dataset.status == active_status) {
        selectOption.classList.add("active-item");
        selectOption.classList.remove("selectable-item");
      }
    }
  }

  hideSearchDropdown() {
    let select_flat_search = document.getElementById('select_flat_search');
    if (select_flat_search != null) {
      select_flat_search.hidden = true;
    }
  }

  hideDomainDropdown() {
    if (this.hasSelectDomainsDropdownTarget) {
      this.addHiddenClassToDropdown(this.selectDomainsDropdownTarget);
    }
  }

  updateImpossibleStatusCount(src, target){
    if(src.value == 0)
      target.style.display = 'none';
    else
      target.style.display = 'inline-block';
    target.querySelector('span').innerHTML = src.value;
  }

  clearImpossibleCountBtn(){
    if(this.hasOnlyImpossibleStatusTarget && this.hasOnlyImpossibleStatusCountTarget){
      this.onlyImpossibleStatusTarget.value = false;
      this.onlyImpossibleStatusCountMirrorTarget.querySelector('i').style.display = 'none';
    }
  }

  resetPortfolioSelectText() {
    this.selectTarget.textContent = 'Tous les portefeuilles';
  }
}
