import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form1', 'form2', 'comment'];

  submitForms(event) {
    event.preventDefault();
    if (this.commentTarget.value != '') {
      this.form1Target.submit();
    }
    setTimeout(() => {
      this.form2Target.submit();
    }, 100);
  }
}
