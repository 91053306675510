import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'rent',
    'area',
    'tightness',
    'standardRental',
    'smallerRental',
    'premiumManagement',
    'standardManagement',
    'vacancyByDay',
    'agencyRental',
    'vacancyDiffInEuros',
    'vacancyDiffInDays',
    'gliManagement',
    'gliStandardManagement',
    'gliPremiumManagement',
  ];

  connect() {
    this.processCalculations();
  }

  processCalculations() {
    this.calculateRates();
    this.calculateVacancy();
  }

  // private

  calculateVacancy() {
    const days_in_month = 30;

    this.vacancyByDayTarget.value = this.rentTarget.value / days_in_month;
    this.agencyRentalTarget.value = this.areaTarget.value * this.agency_day_to_rent();
    this.vacancyDiffInEurosTarget.value = this.standardRentalTarget.value - this.agencyRentalTarget.value;
    this.vacancyDiffInDaysTarget.value = this.vacancyDiffInEurosTarget.value / this.vacancyByDayTarget.value;

    this.vacancyByDayTarget.value = parseFloat(this.vacancyByDayTarget.value).toFixed(2);
    this.vacancyDiffInDaysTarget.value = parseFloat(this.vacancyDiffInDaysTarget.value).toFixed(2);
  }

  calculateRates() {
    const minimum_standard_rate = 29.9;
    const minimum_premium_rate = 34.9;

    this.rateInputs().forEach((rate_input) => {
      let calculated_rate = (this.rentTarget.value * rate_input.dataset.rate) / 100;

      if (rate_input.id === 'standard-management') {
        calculated_rate = Math.max(minimum_standard_rate, calculated_rate);
      }

      if (rate_input.id === 'premium-management') {
        calculated_rate = Math.max(minimum_premium_rate, calculated_rate);
      }

      if (rate_input.id === 'gli-standard-management') {
        calculated_rate = Math.max(minimum_standard_rate + (2.5 * this.rentTarget.value) / 100, calculated_rate);
      }

      if (rate_input.id === 'gli-premium-management') {
        calculated_rate = Math.max(minimum_premium_rate + (2.5 * this.rentTarget.value) / 100, calculated_rate);
      }

      calculated_rate = calculated_rate.toFixed(2);
      rate_input.value = calculated_rate;
    });
  }

  rateInputs() {
    return [
      this.standardRentalTarget,
      this.smallerRentalTarget,
      this.premiumManagementTarget,
      this.standardManagementTarget,
      this.gliManagementTarget,
      this.gliStandardManagementTarget,
      this.gliPremiumManagementTarget,
    ];
  }

  agency_day_to_rent() {
    const agency_high_tightness_area_rate = 15;
    const agency_low_tightness_area_rate = 13;

    return this.tightnessTarget.checked ? agency_high_tightness_area_rate : agency_low_tightness_area_rate;
  }
}
