import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="internal--tenant-contract-nature"
// Depending on the tenant contract nature, set the state of the long duration, furnished and rental charges nature fields
export default class extends Controller {
  static targets = ['longDuration', 'furnished', 'tenantContractNature', 'rentalChargesNature'];
  static values = { tenantContractNaturesData: Object }; // contains the data for each contract nature (see Flat::TENANT_CONTRACT_NATURES_DATA)

  connect() {
    this.setTargetsState(this.tenantContractNatureTarget.value);
  }

  syncFields(event) {
    this.setTargetsState(event.target.value);
  }

  // private

  setTargetsState(tenantContractNature) {
    if (!Object.keys(this.tenantContractNaturesDataValue).includes(tenantContractNature)) return;

    const tenantContractNatureData = this.tenantContractNaturesDataValue[tenantContractNature];

    this.setLongDurationState(tenantContractNatureData);

    if (this.furnishedTarget.type === 'checkbox') {
      this.setFurnishedCheckboxState(tenantContractNatureData.is_furnished);
    } else if (this.furnishedTarget.type === 'hidden') {
      this.setFurnishedHiddenState(tenantContractNatureData.is_furnished);
    }

    this.setRentalChargesNatureState(tenantContractNatureData.rental_charges);
  }

  setLongDurationState({ duration, max_duration }) {
    if (duration !== null) {
      this.longDurationTarget.value = duration;
      this.longDurationTarget.readOnly = true;
    } else {
      this.longDurationTarget.readOnly = false;
    }

    if (max_duration !== null) {
      this.longDurationTarget.setAttribute('max', max_duration);
    } else {
      this.longDurationTarget.setAttribute('max', null);
    }
  }

  setFurnishedCheckboxState(isFurnished) {
    // there is no readonly state on a checkbox, so we disable the pointer events
    if (isFurnished !== null) {
      this.furnishedTarget.checked = isFurnished;
      this.furnishedTarget.style.pointerEvents = 'none';
      this.furnishedTarget.labels[0].style.pointerEvents = 'none';
    } else {
      this.furnishedTarget.style.pointerEvents = 'auto';
      this.furnishedTarget.labels[0].style.pointerEvents = 'auto';
    }
  }

  setFurnishedHiddenState(isFurnished) {
    if (isFurnished !== null) {
      this.furnishedTarget.value = isFurnished;
    }
  }

  setRentalChargesNatureState({ default_value, editable }) {
    if (!this.hasRentalChargesNatureTarget) return;

    if (this.rentalChargesNatureTarget.value === null || !editable) {
      this.rentalChargesNatureTarget.value = default_value;
    }
    this.rentalChargesNatureTarget.disabled = !editable;
  }
}
