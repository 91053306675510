import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';

const application = Application.start();

// Configure Stimulus development experience
application.warnings = true;
application.debug = import.meta.env.DEV;
window.Stimulus = application;

// Load all Stimulus controllers inside javascript/controllers
const controllers = import.meta.glob('~/**/*_controller.js', { eager: true });
registerControllers(application, controllers);

import RailsNestedForm from '@stimulus-components/rails-nested-form';
application.register('nested-form', RailsNestedForm);
