import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="internal--applicant-show"
export default class extends Controller {
  static targets = ['rent', 'charges', 'guarantee'];

  computeFlatGuarantee() {
    if (this.rentTarget.value <= 0 || this.chargesTarget.value <= 0) {
      this.guaranteeTarget.value = '';
      return;
    }
    let guarantee = (this.rentTarget.value - this.chargesTarget.value) * 2;

    if (guarantee < 0) {
      guarantee = 0;
    }
    this.guaranteeTarget.value = guarantee;
  }

  createRentalTransfer(event) {
    let button = event.target;
    button.style.pointerEvents = 'none';
    button.style.opacity = 0.65;
    button.innerHTML = 'Création en cours...';
    fetch(button.dataset.url, {
      method: 'POST',
      headers: {
        Accept: 'text/html',
        'X-Requested-With': 'XMLHttpRequest',
      },
      credentials: 'same-origin',
    })
      .then((response) => response.text())
      .then(() => window.location.reload());
  }
}
