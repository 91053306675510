import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="report-radio-value"
export default class extends Controller {
  static targets = [ 'source', 'destination' ]

  connect() {
    this.reportValue();
  }

  reportValue() {
    let email = '';
    if (this.hasDestinationTarget) {
      this.sourceTargets.forEach(function(source) {
        if (source.checked) {
          email = source.dataset.email;
        }
      })
      this.destinationTarget.value = email;
    }
  }
}
