import { Controller } from '@hotwired/stimulus';

// Connects to add copy event
export default class extends Controller {
  copy(event) {
    navigator.clipboard.writeText(event.target.dataset.textToCopy).then(function () {
      event.target.classList.add('active');
      setTimeout(function () {
        event.target.classList.remove('active');
      }, 2000);
    });
  }
}
