import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="navigation-select"
export default class extends Controller {
  static targets = ['spinner', 'select'];

  navigate({ params: { targetFrame } }) {
    Turbo.visit(this.selectTarget.value, { frame: targetFrame || '_top' });
    this.selectTarget.remove();
    this.spinnerTarget.classList.remove('d-none');
  }
}
