import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="internal--change-email-fields"
export default class extends Controller {
  static targets = [ 'subjectEmail', 'bodyEmail']

  changeSubjectEmail(event) {
    this.subjectEmailTarget.value = event.target.value;
  }

  changeBodyEmail(event) {
    this.bodyEmailTarget.value = event.target.value;
  }
}