// Connects to data-controller='admin--admin-page'
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['allFormerAgenciesCheckbox', 'formerAgencyCheckbox'];

  toggleAll(event) {
    const allChecked = event.target.checked;
    this.formerAgencyCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = allChecked;
    });
  }

  toggleAgency() {
    const allChecked = this.formerAgencyCheckboxTargets.every((checkbox) => checkbox.checked);
    const anyChecked = this.formerAgencyCheckboxTargets.some((checkbox) => checkbox.checked);

    this.allFormerAgenciesCheckboxTarget.checked = allChecked;
    this.allFormerAgenciesCheckboxTarget.indeterminate = !allChecked && anyChecked;
  }
}
