import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = [ "form" ]

    showRentalEvent(element) {
        this.setNewPathRentalEvent(element);
        this.displayOffCanvas();
        this.formRequestSubmit(this.formTarget);
    }

    //private

    setNewPathRentalEvent(element) {
        var new_path = window.location.href
            .replace(window.location.pathname, element.currentTarget.dataset.rentalEventPath);
        this.formTarget.action = new_path;
    }

    displayOffCanvas() {
        var myOffcanvas = document.getElementById('offcanvasRentalShow');
        var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);
        bsOffcanvas.toggle();
    }

    formRequestSubmit(target) {
        if (typeof target.requestSubmit === "function") {
            target.requestSubmit();
        } else {
            var submitter = document.createElement("input");
            submitter.type = "submit";
            submitter.hidden = true;
            target.appendChild(submitter);
            submitter.click();
            target.removeChild(submitter);
        }
    }
}
