import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['caretakerPresence', 'caretakerForm', 'buildingType', 'newCondoFields'];

  initialize() {
    this.inputTypes = ['input', 'select', 'checkbox', 'radio'];
    this.requiredInputs = [];
  }

  connect() {
    this.handleCaretakerPresenceChange();
    this.handleBuildingTypeChange();
  }

  handleBuildingTypeChange() {
    if (this.buildingTypeTarget.value === 'new_b2c') {
      this.#showForm(this.newCondoFieldsTarget);
    } else {
      this.#hideForm(this.newCondoFieldsTarget);
    }
  }

  handleCaretakerPresenceChange() {
    if (this.caretakerPresenceTarget.checked) {
      this.#showForm(this.caretakerFormTarget);
    } else {
      this.#hideForm(this.caretakerFormTarget);
    }
  }

  #showForm(formTarget) {
    formTarget.classList.remove('d-none');
    formTarget.querySelectorAll(this.inputTypes.join(',')).forEach((input) => {
      input.disabled = false;

      // Reset required state for hidden inputs
      if (this.requiredInputs.includes(input.name)) {
        this.requiredInputs = this.requiredInputs.filter((val) => val !== input.name);
        input.required = true;
      }
    });
  }

  #hideForm(formTarget) {
    formTarget.classList.add('d-none');
    formTarget.querySelectorAll(this.inputTypes.join(',')).forEach((input) => {
      input.disabled = true;

      // Remove required state for hidden inputs
      if (input.required) {
        this.requiredInputs.push(input.name);
        input.required = false;
      }
    });
  }
}
