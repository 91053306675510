import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="landlord--notification-filters"
export default class extends Controller {
  static targets = ['dropdownLabel', 'dropdownOptions', 'filterContainer', 'filterReset'];
  static values = { activeDropdown: String };

  connect() {
    this.updateActiveDropdown();
  }

  handleClickOutside(event) {
    if (!this.hasFilterContainerTarget || !this.hasDropdownOptionsTarget) return;

    const clickedOutsideFilters = !this.filterContainerTargets.some((filter) => filter.contains(event.target));
    if (clickedOutsideFilters) {
      this.activeDropdownValue = null;
    }
  }

  handleLabelClick(event) {
    const clickedLabel = this.dropdownLabelTargets.find((label) => label.contains(event.target));
    if (!clickedLabel) return;

    const filter = clickedLabel.closest('.filter-container');
    const dropdown = filter.querySelector('.dropdown-options-container');

    this.activeDropdownValue = dropdown === this.activeDropdownTarget ? null : dropdown.id;
  }

  handleResetButtonClick(event) {
    const clickedResetButton = this.filterResetTargets.find((resetButton) => resetButton.contains(event.target));
    if (!clickedResetButton) return;

    const filter = clickedResetButton.closest('.filter-container');
    const checkedCheckboxes = filter.querySelectorAll('input:checked');
    checkedCheckboxes.forEach((checkbox) => {
      checkbox.checked = false;
      checkbox.dispatchEvent(new Event('input'));
    });

    this.activeDropdownValue = null;
    this.updateActiveDropdown();
  }

  updateActiveDropdown() {
    this.dropdownOptionsTargets.forEach((option) => {
      option.style.display = option.id === this.activeDropdownValue ? 'block' : 'none';
    });

    this.dropdownLabelTargets.forEach((label) => {
      const filter = label.closest('.filter-container');
      const dropdown = filter.querySelector('.dropdown-options-container');
      const checkboxChecked = !!dropdown.querySelectorAll('input:checked').length;

      if (checkboxChecked) {
        label.classList.add('active');
        label.querySelector('.filter-reset').classList.remove('d-none');
      } else if (dropdown.style.display === 'block') {
        label.classList.add('active');
        label.querySelector('.filter-reset').classList.add('d-none');
      } else {
        label.classList.remove('active');
        label.querySelector('.filter-reset').classList.add('d-none');
      }
    });
  }

  activeDropdownValueChanged() {
    this.updateActiveDropdown();
  }

  get activeDropdownTarget() {
    return (
      this.hasDropdownOptionsTarget &&
      this.dropdownOptionsTargets.find((option) => option.id === this.activeDropdownValue)
    );
  }
}
