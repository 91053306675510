import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "tbody" ]
  static values = { count: Number, url: String }

  remove() {
    event.currentTarget.closest('tr').remove();
  }

  add() {
    this.countValue++;
    fetch(`${this.urlValue}?count=${this.countValue}`)
      .then(response => response.text())
      .then(html => this.tbodyTarget.insertAdjacentHTML('beforeend', html))
  }
}
