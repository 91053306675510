import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="agent--await-load"
export default class extends Controller {
  static targets = ['iframe', 'nextStepButton'];
  connect() {
    this.iframeTarget.addEventListener('load', () => {
      // The iframe has finished loading
      this.nextStepButtonTarget.disabled = false;
    });
  }
}
